<template>
  <div>
    <v-select
      v-model="showpersen"
      :options="showuser"
      :searchable="true"
      :clearable="true"
    ></v-select>
    <button class="btn btn-primary my-2" @click="Submit()">
      <span v-if="!loading">ตรวจสอบยูส</span>
      <div class="spinner-border" role="status" v-else>
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
    <button class="btn btn-warning my-2 mx-2" @click="Role()">
      <span v-if="!loading">แก้ไขสิทธิ์</span>
      <div class="spinner-border" role="status" v-else>
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
    <div v-for="(user, i) in alluser" :key="i">
      <div
        class="card my-2"
        :class="user.status === 'Normal' ? 'bg-light' : 'bg-danger'"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-7">
              user_sub : {{ user.username }} <br />
              role : {{ user.role }}
            </div>
            <div class="col-5 text-right" style="text-align-last: end">
              <button
                class="btn btn-success btn-sm"
                v-if="user.status === 'Normal'"
                @click="submitresetpassword(user.username)"
              >
                <span v-if="!loading">Reset รหัสผ่าน</span>
                <div class="spinner-border" role="status" v-else>
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="allpo">
      <label for="po">เลือกสิทธิ์ที่จะแก้ไข</label>
      <v-select
        v-model="selectposet"
        :options="selectpo"
        :searchable="true"
        :clearable="true"
      ></v-select>
      <button class="btn btn-success btn-sm my-2" @click="showpo()">
        ดูสถานะสิทธิ์
      </button>
      <div v-if="selectposet">
        <h1>รายการ Checkbox</h1>
        <ul>
          <li v-for="(item, index) in this.allpo.permission" :key="index">
            <input
              type="checkbox"
              v-model="this.allpo.permission[index].status"
            />
            {{ item.title_th }}
          </li>
        </ul>
      </div>
      <button
        class="btn btn-primary mb-2"
        @click="update_po()"
        v-if="selectposet"
      >
        <span v-if="!loading"> อัพเดทสิทธิ์</span>
        <div class="spinner-border" role="status" v-else>
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </div>
  </div>
</template>
<script>
let domain = "";
export default {
  name: "ProjectRobotsResetUsersub",
  data() {
    return {
      showuser: [],
      loading: false,
      showpersen: null,
      alluser: null,
      allpo: null,
      checkedItems: [],
      selectpo: [],
      selectposet: null,
    };
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
      this.GetAllData();
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
      this.GetAllData();
    }
  },
  methods: {
    GetAllData() {
      this.loading = true;
      this.axios
        .get(domain + "/api/GetAlluser")
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            result.data.data.forEach((element) => {
              this.showuser.push({
                value: element,
                label: element.username,
              });
            });
            // this.showuser;
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.msg,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    Submit() {
      this.loading = true;
      this.axios
        .get(
          `${domain}/api/getusersub?user=${this.showpersen.value.username}&type=${this.showpersen.value.type}`
        )
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.payload,
              icon: "success",
            });
            this.alluser = result.data.data;
            this.allpo = null;
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.message,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    submitresetpassword(usersub) {
      this.$swal({
        title: "ยืนยันการ reset รหัสผ่าน",
        text: "รหัสผ่านคือ Xx112233",
        showCancelButton: true,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.axios
              .get(
                `${domain}/api/resetpassword?user=${this.showpersen.value.username}&type=${this.showpersen.value.type}&usersub=${usersub}`
              )
              .then((result) => {
                console.log(result.data);
                this.loading = false;
                if (result.data.code === 0) {
                  this.$swal({
                    title: "สำเร็จ",
                    text: result.data.payload,
                    icon: "success",
                  });
                } else {
                  this.$swal({
                    title: "เกิดข้อผิดพลาด",
                    text: result.data.message,
                    icon: "error",
                  });
                }
              })
              .catch((err) => {
                this.loading = false;
                return err;
              });
          }
        })
        .catch((err) => {
          return err;
        });
    },
    Role() {
      this.loading = true;
      this.allpo = null
      this.selectpo = []
      this.axios
        .get(
          `${domain}/api/getrolesub?user=${this.showpersen.value.username}&type=${this.showpersen.value.type}`
        )
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.payload,
              icon: "success",
            });
            this.allpo = result.data;
            this.alluser = null;
            
            this.allpo.data.forEach((element) => {
              this.selectpo.push({
                value: element,
                label: element.name,
              });
            });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.message,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    showpo() {
      for (let i = 0; i < this.allpo.permission.length; i++) {
        let found = false;
        for (let j = 0; j < this.selectposet.value.permissions.length; j++) {
          if (
            this.allpo.permission[i].code ===
            this.selectposet.value.permissions[j]
          ) {
            found = true;
            break;
          }
        }
        if (found) {
          this.allpo.permission[i].status = true;
        } else {
          this.allpo.permission[i].status = false;
        }
      }
    },
    update_po() {
      let po = []
      this.allpo.permission.forEach(element => {
        if(element.status) {
          po.push(element.code)
        }
      });
      let body = {
        useragent : this.showpersen.value.username,
        type : this.showpersen.value.type,
        name_rule: this.selectposet.value.name,
        group_id: this.selectposet.value.group_id,
        permissions: po
      }
      this.loading = true;
      this.axios
        .post(
          `${domain}/api/updaterule`,body
        )
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.payload,
              icon: "success",
            });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.message,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
  },
};
</script>

<style></style>
