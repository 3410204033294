<template>
  <div class="createsenior">
    <label for="create_Senior">สร้าง Senior</label>
    <div class="input-group mb-3">
      <input
        type="text"
        v-model="username_senior"
        class="form-control"
        placeholder="กรอกชื่อ senior"
      />
      <button
        @click="Create_senior()"
        class="btn btn-primary"
        :disabled="loading"
      >
        <span v-if="!loading">ยืนยัน</span>
        <div class="spinner-border" role="status" v-else>
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      username_senior: null,
      loading: false,
    };
  },
  methods: {
    Create_senior() {
      const regex = /^[a-zA-Z0-9]+$/;
      const thaiRegex = /[ก-๙]/;
      const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
      if (
        regex.test(this.username_senior) &&
        !thaiRegex.test(this.username_senior) &&
        !specialCharRegex.test(this.username_senior)
      ) {
        let body = {
          username: this.username_senior.toLowerCase(),
        };
        this.loading = true;
        this.axios
          .post(domain + "/api/CreateSenior", body)
          .then((result) => {
            this.loading = false;
            if (result.data.code === 0) {
              this.$swal({
                title: "สำเร็จ",
                text: result.data.msg,
                icon: "success",
              });
            } else {
              this.$swal({
                title: "เกิดข้อผิดพลาด",
                text: result.data.payload.message.message,
                icon: "error",
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      } else {
        this.$swal({
          title: "เกิดข้อผิดพลาด",
          text: "ต้องกรอกยูสเซอร์เป็นภาษาอังกฤษหรือตัวเลขเท่านั้น",
          icon: "error",
        });
      }
    },
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
    }
  },
};
</script>

<style></style>
