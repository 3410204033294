<template>
  <div class="createsenior my-3">
    <label for="create_Senior">ยูส Senior</label>
    <v-select
      v-model="selectsenior"
      :options="allsenior"
      :searchable="true"
      :clearable="true"
    ></v-select>
    <button class="btn btn-primary my-2" @click="Get_list_game()">
      <span v-if="!loading">เช็ครายการเกม</span>
      <div class="spinner-border" role="status" v-else>
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
    <button
      class="btn btn-danger mx-2"
      @click="Set_game(false)"
      v-if="data_game_all"
    >
      <span v-if="!loading">ปิดเกม</span>
      <div class="spinner-border" role="status" v-else>
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
    <button
      class="btn btn-success mx-2"
      @click="Set_game(true)"
      v-if="data_game_all"
    >
      <span v-if="!loading">เปิดเกม</span>
      <div class="spinner-border" role="status" v-else>
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
    <div v-if="data_game_all" class="my-2">
      <label for="select_data">เลือกปิดเกมตาม partner</label>
      <v-select
        v-model="select_all_data_disable_game"
        :options="all_data_disable_game"
        :searchable="true"
        :clearable="true"
      ></v-select>
      <button class="btn btn-warning my-2" @click="CheckData()">ยืนยัน</button>
    </div>
    <table
      class="table table-bordered border-primary text-center"
      v-if="data_game_all"
    >
      <thead>
        <tr>
          <td>ลำดับ</td>
          <td>รูปเกม</td>
          <td>สถานะเกม</td>
          <td>เลือกเกม</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(e, i) in data_game_all" :key="i">
          <td>{{ i + 1 }}</td>
          <td>
            <img :src="e.game.image_path" alt="" style="max-height: 2rem" />
            <br />
            {{ e.game.name }}
          </td>
          <td>
            <span class="text-success" v-if="e.is_game_enable"
              >{{ e.is_game_enable ? "เปิด" : "ปิด" }}
            </span>
            <span v-else class="text-danger">{{
              e.is_game_enable ? "เปิด" : "ปิด"
            }}</span>
          </td>
          <td class="text-center">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="e.selectgame"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      username_senior: null,
      username_master: null,
      loading: false,
      allsenior: [],
      selectsenior: null,
      data_game_all: null,
      all_data_disable_game: [],
      select_all_data_disable_game: null,
    };
  },
  methods: {
    Get_master() {
      this.axios
        .get(domain + "/api/GetMaster")
        .then((result) => {
          if (result.data.code === 0) {
            result.data.payload.forEach((element) => {
              this.allsenior.push({
                value: element.username,
                label: element.username,
              });
            });
            // alert(result.data.message.message);
          } else {
            alert(result.data.msg);
          }
        })
        .catch((err) => {
          return err;
        });
    },
    Get_list_game() {
      this.loading = true;
      this.data_game_all = null;
      this.axios
        .get(domain + `/api/getgameSenior?username=${this.selectsenior.value}`)
        .then((result) => {
          this.loading = false;
          this.data_game_all = result.data.payload;
          this.get_disable_game();
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    Set_game(status) {
      this.loading = true;
      let data_set = [];
      this.data_game_all.forEach((element) => {
        if (element.selectgame) {
          data_set.push({
            game_id: element.game_id,
            game_name: element.game.name,
          });
        }
      });
      let body = {
        status: status,
        username: this.selectsenior.value,
        payload: data_set,
      };
      this.axios
        .post(domain + `/api/close_game_senior`, body)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "success",
              type: "success",
            });
            this.Get_list_game();
          } else {
            this.$swal({
              title: "error",
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    get_disable_game() {
      this.all_data_disable_game = [];
      this.axios
        .get(domain + "/api/disable_game")
        .then((result) => {
          if (result.data.code === 0) {
            result.data.payload.forEach((element) => {
              this.all_data_disable_game.push({
                value: element.disable,
                label: element.name,
              });
            });
            // alert(result.data.message.message);
          } else {
            alert(result.data.msg);
          }
        })
        .catch((err) => {
          return err;
        });
    },
    CheckData() {
      this.data_game_all.forEach((element) => {
        this.select_all_data_disable_game.value.forEach((element2) => {
          if (element.game.name.toLowerCase() === element2.toLowerCase()) {
            element.selectgame = true;
          }
        });
      });
    },
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
      this.Get_master();
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
      this.Get_master();
    }
  },
};
</script>

<style></style>
