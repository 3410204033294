<template>
  <div class="createsenior">
    <label for="create_Senior">ยูส Senior</label>
    <v-select
      v-model="selectsenior"
      :options="allsenior"
      :searchable="true"
      :clearable="true"
    ></v-select>
    <div class="mt-2" v-if="selectsenior">
      <label for="create_Senior">สร้าง Master</label>
      <div class="input-group mb-3">
        <input type="text" v-model="username_master" class="form-control" />
        <button
          @click="Create_senior()"
          class="btn btn-primary"
          :disabled="loading"
        >
          <span v-if="!loading">ยืนยัน</span>
          <div class="spinner-border" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      username_senior: null,
      username_master: null,
      loading: false,
      allsenior: [],
      selectsenior: null,
    };
  },
  methods: {
    Create_senior() {
      const regex = /^[a-zA-Z0-9]+$/;
      const thaiRegex = /[ก-๙]/;
      const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
      if (
        regex.test(this.username_master) &&
        !thaiRegex.test(this.username_master) &&
        !specialCharRegex.test(this.username_master)
      ) {
        let body = {
          usernamesenior: this.selectsenior,
          usernamemaster: this.username_master.toLowerCase(),
        };
        this.loading = true;
        this.axios
          .post(domain + "/api/CreateMaster", body)
          .then((result) => {
            this.loading = false;
            if (result.data.code === 0) {
              this.$swal({
                title: "สำเร็จ",
                text: result.data.msg,
                icon: "success",
              });
            } else {
              this.$swal({
                title: "เกิดข้อผิดพลาด",
                text: result.data.payload.message.message,
                icon: "error",
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      } else {
        this.$swal({
          title: "เกิดข้อผิดพลาด",
          text: "ต้องกรอกยูสเซอร์เป็นภาษาอังกฤษหรือตัวเลขเท่านั้น",
          icon: "error",
        });
      }
    },
    Get_master() {
      this.axios
        .get(domain + "/api/GetMaster")
        .then((result) => {
          if (result.data.code === 0) {
            result.data.payload.forEach((element) => {
              this.allsenior.push({
                value: element.username,
                label: element.username,
              });
            });
            // alert(result.data.message.message);
          } else {
            alert(result.data.msg);
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
  mounted() {
  
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
      this.Get_master();
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
      this.Get_master();
    }
  },
};
</script>

<style></style>
