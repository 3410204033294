<template>
  <div class="home">
    <center>System ALL WEB</center>

    <table class="table">
      <thead>
        <tr class="text-center">
          <th scope="col">#</th>
          <th scope="col">game</th>
          <th scope="col">id</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in all_data" :key="index" class="text-center">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ item.game }}</td>
          <td>{{ item.game_id }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
let domain = "";
export default {
  name: "HomeView",
  data() {
    return {
      all_data: null,
    };
  },
  components: {},
  methods: {
    Get_datagame() {
      this.axios
        .get(domain + "/api/listGameSuperCompany")
        .then((result) => {
          console.log(result);
          this.all_data = result.data.payload;
        })
        .catch((err) => {
          return err;
        });
    },
  },
  mounted() {
    // if (process.env.NODE_ENV !== "development") {
    //   domain = location.origin;
    //   console.log(domain);
    //   this.Get_datagame();
    // } else {
    //   domain = location.origin;
    //   console.log(domain);
    //   this.Get_datagame();
    // }
    this.$router.push('/login_web')
  },
};
</script>
