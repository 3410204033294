<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <label for="domain_web" class="mt-2"><h6>โดเมนเว็บ (ไม่มี https)</h6></label
    ><input type="text" v-model="domain_web" class="form-control" />
    <label for="domain_office" class="mt-2">
      <h6>โดเมน office (ไม่มี https)</h6></label
    >
    <input type="text" v-model="domain_office" class="form-control" />
    <label for="domain_office" class="mt-2"> <h6>เลือกธีม</h6></label>
    <v-select
      v-model="selecttheme"
      :options="datatheme"
      :searchable="true"
      :clearable="true"
      class="my-2"
    ></v-select>
    <label for="domain_office" class="mt-2"> <h6>username office</h6></label>
    <input type="text" v-model="user_office" class="form-control" />
    <label for="domain_office" class="mt-2"> <h6>password office</h6></label>
    <input type="text" v-model="pw_office" class="form-control" />
    <label for="domain_office" class="mt-2"> <h6>pin office</h6></label>
    <input type="number" v-model="pin_office" class="form-control" />
    <center>
      <button class="btn btn-primary mt-2" @click="Submit()">
        <span v-if="!loading"> ยืนยัน</span>
        <div class="spinner-border" role="status" v-else>
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </center>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      loading: false,
      domain_web: null,
      domain_office: null,
      name_service: null,
      key_agent: null,
      data_install: [],
      data_select: null,
      data_theme: [],
      data_select_theme: null,
      user_office: null,
      pw_office: null,
      pin_office: null,
      datatheme: [
        {
          value: {
            name: "apexpro",
          },
          label: "apexpro",
        },
        {
          value: {
            name: "lobby",
          },
          label: "lobby",
        },
        {
          value: {
            name: "ufa",
          },
          label: "ufa",
        },
        {
          value: {
            name: "bumblebee",
          },
          label: "bumblebee",
        },
      ],
      selecttheme: null,
    };
  },
  methods: {
    Submit() {
      if (
        this.user_office &&
        this.pw_office &&
        this.pin_office &&
        this.domain_web &&
        this.domain_office
      ) {
        const body = {
          domain_office: this.domain_office,
          domain_web: this.domain_web,
          theme: this.selecttheme.value.name,
          username: this.user_office,
          password: this.pw_office,
          pin: this.pin_office,
        };
        this.loading =  true
        this.axios
          .post(domain + "/api/officesetupnew", body, { timeout: 0 })
          .then((result) => {
            this.loading = false;
            if (result.data.code === 0) {
              this.$swal({
                title: "สำเร็จ",
                icon: "success",
              });
            } else {
              this.$swal({
                title: "เกิดข้อผิดพลาด",
                text: result.data.msg,
                icon: "error",
              });
            }
          })
          .catch((err) => {
            return err;
          });
      } else {
        this.$swal({
          title: "เกิดข้อผิดพลาด",
          text: "กรุณากรอกข้อมูลให้ครบ",
          icon: "error",
        });
      }
    },
  },
  mounted() {
    console.log(this.selecttheme);
    if (localStorage.getItem("token")) {
      if (process.env.NODE_ENV !== "development") {
        domain = location.origin;
        console.log(domain);
      } else {
        domain = "http://localhost:6900";
      }
    } else {
      this.$router.push("/Login_web");
    }
  },
};
</script>

<style></style>
