<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <label for="domain_web" class="mt-2"><h6>เลือกโปรเจค</h6></label>
    <v-select
      v-model="data_select"
      :options="data_install"
      :searchable="true"
      :clearable="true"
    ></v-select>
    <label for="domain_web" class="mt-2"><h6>เลือก THEME</h6></label>
    <v-select
      v-model="data_select_theme"
      :options="data_theme"
      :searchable="true"
      :clearable="true"
    ></v-select>
    <label for="domain_web" class="mt-2"><h6>โดเมนเว็บ (ไม่มี https)</h6></label
    ><input type="text" v-model="domain_web" class="form-control" />
    <label for="domain_office" class="mt-2">
      <h6>โดเมน office (ไม่มี https)</h6></label
    >
    <input type="text" v-model="domain_office" class="form-control" />
    <label for="name_service" class="mt-2"><h6>ชื่อ service</h6></label>
    <input type="text" v-model="name_service" class="form-control" />
    <label for="name_service" class="mt-2"><h6>key agent</h6></label>
    <input type="text" v-model="key_agent" class="form-control" />

    <center>
      <button class="btn btn-primary mt-2" @click="Submit()">
        <span v-if="!loading"> ยืนยัน</span>
        <div class="spinner-border" role="status" v-else>
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </center>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      loading: false,
      domain_web: null,
      domain_office: null,
      name_service: null,
      key_agent: null,
      data_install: [],
      data_select: null,
      data_theme: [],
      data_select_theme: null,
    };
  },
  methods: {
    Submit() {
      const param = `domain_web=${this.domain_web}`;
      const param2 = `domain_office=${this.domain_office}`;
      const param3 = `name_service=${this.name_service}`;
      const param4 = `key_agent=${this.key_agent}`;
      const param6 = `project=${this.data_select.value}`;
      const param7 = `theme=${this.data_select_theme.value}`;
      this.loading = true;
      this.axios
        .get(
          `${domain}/api/installweb?${param}&${param2}&${param3}&${param4}&${param6}&${param7}`
        )
        .then((result) => {
          this.loading = false;
          if (result.data.code == 0) {
            this.$swal({
              title: "success",
              icon: "success",
            });
          } else {
            this.$swal({
              title: "error",
              text: result.data.msg,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          return err;
        });
    },
    GetData_InstallWeb() {
      this.axios
        .get(`${domain}/api/datainstallweb`)
        .then((result) => {
          result.data.payload.project.forEach((element) => {
            this.data_install.push({
              value: element,
              label: element,
            });
          });
          result.data.payload.theme.forEach((element) => {
            this.data_theme.push({
              value: element,
              label: element,
            });
          });
        })
        .catch((err) => {
          return err;
        });
    },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      if (process.env.NODE_ENV !== "development") {
        domain = location.origin;
        console.log(domain);
        this.GetData_InstallWeb();
      } else {
        this.GetData_InstallWeb();
        domain = "http://localhost:6900";
      }
    } else {
      this.$router.push("/Login_web");
    }
  },
};
</script>

<style></style>
