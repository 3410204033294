// store/auth.js
export default {
  state: {
    isLoggedIn: false,
    user: null
  },
  mutations: {
    SET_LOGIN_STATUS(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    SET_USER(state, user) {
      state.user = user;
    }
  },
  actions: {
    login({ commit }, user) {
      commit('SET_LOGIN_STATUS', true);
      commit('SET_USER', user);
    },
    logout({ commit }) {
      commit('SET_LOGIN_STATUS', false);
      commit('SET_USER', null);
    }
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    user: state => state.user
  }
};
