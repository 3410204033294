<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div v-if="!domain_web_status">
      <div v-if="!status_delete && !status_setdomain">
        <label for="name_service" class="mt-2"><h6>โดเมน</h6></label>
        <input type="text" v-model="name_service" class="form-control" />
        <div class="my-2">
          <label for="create_Senior">ยูส cloudflare</label>
          <v-select
            v-model="selectaccount"
            :options="allaccount"
            :searchable="true"
            :clearable="true"
            @open="getAccount"
          ></v-select>
        </div>
        <center>
          <button class="btn btn-primary mt-2" @click="SubmitDomain()">
            <span v-if="!loading"> เพิ่มโดเมน</span>
            <div class="spinner-border" role="status" v-else>
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
        </center>
      </div>
    </div>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      name_service: null,
      loading: false,
      selectaccount: null,
      allaccount: [],
    };
  },
  methods: {
    getAccount() {
      if (this.allaccount <= 0) {
        this.axios
          .get(domain + "/api/get_account_cloudflare")
          .then((result) => {
            if (result.data.code === 0) {
              this.allaccount = [];
              result.data.payload.forEach((element) => {
                console.log(element);
                if (
                  element.name !== "Cf001@hpktechnology.com" &&
                  element.name !== "Gte.lamborghini@gmail.com" &&
                  element.name !== "Hpx.helloworld@gmail.com"
                ) {
                  this.allaccount.push({
                    value: {
                      account: element.name,
                      id: element.id,
                    },
                    label: element.name,
                  });
                }
              });
            }
          })
          .catch((err) => {
            return err;
          });
      }
    },
    SubmitDomain() {
      if (this.name_service && this.selectaccount) {
        const body = {
          domain: this.name_service,
          account: this.selectaccount.value.account,
          id: this.selectaccount.value.id,
        };
        this.loading = true;
        this.axios
          .post(domain + "/api/Insert_domain", body)
          .then((result) => {
            this.loading = false;
            if (result.data.code === 0) {
              this.$swal({
                title: "สำเร็จ",
                html: `nameserver คือ <br> ${result.data.payload}`,
                icon: "success",
              });
            } else {
              this.$swal({
                title: "เกิดข้อผิดพลาด",
                text: result.data.msg,
                icon: "error",
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      }
    },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      if (process.env.NODE_ENV !== "development") {
        domain = location.origin;
        console.log(domain);
      } else {
        domain = "http://localhost:6900";
      }
    } else {
      this.$router.push("/Login_web");
    }
  },
};
</script>

<style></style>
