<template>
  <div class="createsenior">
    <div class="row justify-content-center text-center">
      <div class="col-10">
        <label for="create_Senior">เลือกยูสที่ต้องการเช็ค</label>
        <v-select
          v-model="showpersen"
          :options="showuser"
          :searchable="true"
          :clearable="true"
        ></v-select>
        <button
          v-if="showpersen"
          class="btn btn-success mx-1 my-2"
          @click="GetPersen()"
          :disabled="loading"
        >
          <span v-if="!loading">เช็คเปอร์เซ็น agent</span>
          <div class="spinner-border" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <table class="table text-left" v-if="showdata_persen">
          <thead>
            <tr class="text-left">
              <th scope="col">#</th>
              <th scope="col">เกม</th>
              <th scope="col">เปอร์เซ็น</th>
              <th scope="col">สถานะ</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in showdata_persen"
              :key="index"
              class="text-left"
            >
              <th scope="row">{{ index + 1 }}</th>
              <td
                class="text-left"
                :class="
                  item.game_name === 'sbo' || item.game_name === 'saba'
                    ? 'text-danger'
                    : ''
                "
              >
                {{ item.game_name }}
              </td>
              <td
                :class="
                  item.game_name === 'sbo' || item.game_name === 'saba'
                    ? 'text-danger'
                    : ''
                "
              >
                {{ item.share }}
              </td>
              <td :class="item.statusgame ? 'text-success' : 'text-danger'">
                <span v-if="item.statusgame"><small>เปิด</small></span>
                <span v-else>ปิด</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      showpersen: null,
      showuser: [],
      showdata_persen: null,
      loading: false,
    };
  },
  methods: {
    GetAllData() {
      this.loading = true;
      this.axios
        .get(domain + "/api/GetAlluser")
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            result.data.data.forEach((element) => {
              this.showuser.push({
                value: element,
                label: element.username,
              });
            });
            // this.showuser;
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.msg,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    GetPersen() {
      if (this.showpersen) {
        let body = {
          username: this.showpersen.value.username,
          type: this.showpersen.value.type,
          parent: this.showpersen.value.parent,
        };
        console.log(body);
        this.loading = true;
        this.axios
          .post(domain + "/api/CheckPersenAgent", body)
          .then((result) => {
            this.loading = false;
            if (result.data.code === 0) {
              // this.showuser;
              this.showdata_persen = result.data.data;
            } else {
              this.$swal({
                title: "เกิดข้อผิดพลาด",
                text: result.data.msg,
                icon: "error",
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      } else {
        this.$swal({
          title: "เกิดข้อผิดพลาด",
          text: "กรุณาเลือก user",
          icon: "error",
        });
      }
    },
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
      this.GetAllData();
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
      this.GetAllData();
    }
  },
};
</script>

<style></style>
