<template>
  <div id="createsub" class="mt-3">
    <h3>สร้างยูสซับ</h3>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="flexCheckDefault"
        v-model="status_subsenior"
      />
      <label class="form-check-label" for="flexCheckDefault">
        สร้าง subsenior
      </label>
      <div v-if="status_subsenior">
        <h5>Senior</h5>
        <v-select
          v-model="selectsenior"
          :options="allsenior"
          :searchable="true"
          :clearable="true"
        ></v-select>
        <button
          class="btn btn-primary mt-2"
          @click="Submit('senior', selectsenior.value)"
        >
          <span v-if="!loading"> ยืนยัน</span>
          <div class="spinner-border" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </div>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="flexCheckDefault"
        v-model="status_submaster"
      />
      <label class="form-check-label" for="flexCheckDefault">
        สร้าง submaster
      </label>
      <div v-if="status_submaster">
        <h5>Master</h5>
        <v-select
          v-model="selectmaster"
          :options="allmaster"
          :searchable="true"
          :clearable="true"
        ></v-select>
        <button
          class="btn btn-primary mt-2"
          @click="Submit('master', selectmaster.value)"
        >
          <span v-if="!loading"> ยืนยัน</span>
          <div class="spinner-border" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </div>
    </div>
    <!-- /api/CreateSub -->
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      allsenior: [],
      selectsenior: null,
      allmaster: [],
      selectmaster: null,
      status_subsenior: false,
      status_submaster: false,
      loading: false,
    };
  },
  methods: {
    Get_master() {
      this.axios
        .get(domain + "/api/GetMaster")
        .then((result) => {
          if (result.data.code === 0) {
            result.data.payload.forEach((element) => {
              this.allsenior.push({
                value: element.username,
                label: element.username,
              });
            });
            // alert(result.data.message.message);
          } else {
            alert(result.data.msg);
          }
        })
        .catch((err) => {
          return err;
        });
    },
    getmaster() {
      this.axios
        .get(domain + "/api/GetUserMaster")
        .then((result) => {
          // this.allmaster = result.data;
          result.data.forEach((element) => {
            this.allmaster.push({
              value: element.username,
              label: element.username,
            });
          });
        })
        .catch((err) => {
          return err;
        });
    },
    Submit(type, username) {
      this.loading = true;
      let body = {
        types: type,
        usernames: username,
      };
      this.axios
        .post(domain + "/api/CreateSub", body)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.user.username,
              icon: "success",
            });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.data,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
      this.Get_master();
      this.getmaster();
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
      this.Get_master();
      this.getmaster();
    }
  },
};
</script>

<style></style>
