<template>
  <div class="form-check container mt-3">
    <div id="select1" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_senior"
      />
      <label class="form-check-label" for="flexCheckDefault">
        สร้าง senior
      </label>
      <div v-if="status_senior" class="row justify-content-center">
        <div class="col-10">
          <createsenior />
        </div>
      </div>
    </div>
    <div id="select2" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_master"
      />
      <label class="form-check-label" for="flexCheckDefault">
        สร้าง master
      </label>
      <div v-if="status_master" class="row justify-content-center">
        <div class="col-10">
          <createmaster />
        </div>
      </div>
    </div>
    <div id="select3" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_agent"
      />
      <label class="form-check-label" for="flexCheckDefault">
        สร้าง agent
      </label>
      <div v-if="status_agent">
        <createweb />
      </div>
    </div>
    <div id="select4" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_sub_agent"
      />
      <label class="form-check-label" for="flexCheckDefault">
        สร้าง sub_agent
      </label>
      <div v-if="status_sub_agent"><createagent /></div>
    </div>
    <div id="select5" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_persen"
      />
      <label class="form-check-label" for="flexCheckDefault">
        เช็คเปอร์เซ็น
      </label>
      <div v-if="status_persen">
        <Persen_all />
      </div>
    </div>
    <div id="select6" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_mockpersen"
      />
      <label class="form-check-label" for="flexCheckDefault">
        Mock เปอร์เซ็น
      </label>
      <div v-if="status_mockpersen">
        <mockpersen />
      </div>
    </div>
    <div id="select6" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_setpersen"
      />
      <label class="form-check-label" for="flexCheckDefault">
        Setup เปอร์เซ็น
      </label>
      <div v-if="status_setpersen">
        <Set_persen />
      </div>
    </div>
    <div id="select6" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_opengame"
      />
      <label class="form-check-label" for="flexCheckDefault">
        ปิด-เปิดเกม senior
      </label>
      <div v-if="status_opengame">
        <openGameSenior />
      </div>
    </div>
    <div id="select7" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_setkeysenior"
      />
      <label class="form-check-label" for="flexCheckDefault">
        เซ็ต keysenior
      </label>
      <div v-if="status_setkeysenior">
        <Set_keysenior />
      </div>
    </div>
    <!-- <div id="select8">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_setallgamesenior"
      />
      <label class="form-check-label" for="flexCheckDefault">
        เซ็ต allgamesenior
      </label>
      <div v-if="status_setallgamesenior">
        <Set_all_game_senior />
      </div>
    </div> -->
    <div id="select8" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_officeweb"
      />
      <label class="form-check-label" for="flexCheckDefault">
        จัดการหน้าเว็บ
      </label>
      <div v-if="status_officeweb">
        <Setting_office />
      </div>
    </div>
    <div id="select9" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="stats_check_line"
      />
      <label class="form-check-label" for="flexCheckDefault">
        เช็คจำนวน callbackline
      </label>
      <div v-if="stats_check_line">
        <CheckLine />
      </div>
    </div>
    <div id="select10" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_sub_seniormaster"
      />
      <label class="form-check-label" for="flexCheckDefault">
        สร้าง sub senior master (ตัวใหม่)
      </label>
      <div v-if="status_sub_seniormaster">
        <CreateSub />
      </div>
    </div>
    <div id="select11" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_setkey_new_agent"
      />
      <label class="form-check-label" for="flexCheckDefault">
        เซ็ต key new Agent
      </label>
      <div v-if="status_setkey_new_agent">
        <SetKeyNewAgent />
      </div>
    </div>
    <div id="select12" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_check_winloss_web"
      />
      <label class="form-check-label" for="flexCheckDefault">
        จัดการเว็บ
      </label>
      <div v-if="status_check_winloss_web">
        <checkwinlossweb />
      </div>
    </div>
    <div id="select13" v-if="level_user >= 5">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_reset_user_sub"
      />
      <label class="form-check-label" for="flexCheckDefault">
        Reset รหัสผ่านยูสซับ
      </label>
      <div v-if="status_reset_user_sub">
        <ResetUsersub />
      </div>
    </div>
    <div id="select12" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_lotto"
      />
      <label class="form-check-label" for="flexCheckDefault">
        สร้างยูสหวย
      </label>
      <div v-if="status_lotto">
        <createlotto />
      </div>
    </div>
    <div id="select13" v-if="level_user >= 3">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_editdomain"
      />
      <label class="form-check-label" for="flexCheckDefault">
        แก้ไขโดเมน
      </label>
      <div v-if="status_editdomain">
        <Editdomain />
      </div>
    </div>
    <div id="select14" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_install_web"
      />
      <label class="form-check-label" for="flexCheckDefault"> ขึ้นเว็บ </label>
      <div v-if="status_install_web">
        <Installweb />
      </div>
    </div>
    <div id="select15" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_defaultnewoffice"
      />
      <label class="form-check-label" for="flexCheckDefault"> เซ็ต default web </label>
      <div v-if="status_defaultnewoffice">
        <setdefaultoffice />
      </div>
    </div>
    <!-- <div id="select16" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_checkdomain"
      />
      <label class="form-check-label" for="flexCheckDefault">  ดูลิ้งโดเมน </label>
      <div v-if="status_checkdomain">
        <getDomain />
      </div>
    </div> -->
    <div id="select17" v-if="level_user >= 3">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_changetheme"
      />
      <label class="form-check-label" for="flexCheckDefault">
        เปลี่ยนธีม
      </label>
      <div v-if="status_changetheme">
        <changeTheme />
      </div>
    </div>
    <div id="select18" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_setip"
      />
      <label class="form-check-label" for="flexCheckDefault">  แอด IP Agent </label>
      <div v-if="status_setip">
        <addIp />
      </div>
    </div>
    <div id="select19" v-if="level_user >= 10">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_changekey"
      />
      <label class="form-check-label" for="flexCheckDefault">  เปลี่ยน keyagent </label>
      <div v-if="status_changekey">
        <chengekey />
      </div>
    </div>
    <div id="select20" v-if="level_user >= 3">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_multidomain"
      />
      <label class="form-check-label" for="flexCheckDefault">  โดเมนสำรองเว็บ </label>
      <div v-if="status_multidomain">
        <multidomainWeb />
      </div>
    </div>
    <div id="select20" v-if="level_user >= 2">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_insert_domain"
      />
      <label class="form-check-label" for="flexCheckDefault">   เพิ่มโดเมน cloudflare </label>
      <div v-if="status_insert_domain">
        <InsertDomain />
      </div>
    </div>
  </div>
</template>

<script>
let domain = "";
import createmaster from "./CreateMaster.vue";
import createsenior from "./CreateSenior.vue";
import createweb from "./CreateWeb.vue";
import Persen_all from "./Persen_all.vue";
import createagent from "./CreateSubAgent.vue";
import Set_persen from "./Set_persen.vue";
import Set_keysenior from "./SetKeySenior.vue";
import Setting_office from "./SettingOffice.vue";
import CheckLine from "./CheckLine.vue";
import CreateSub from "./CreateSub.vue";
import SetKeyNewAgent from "./SetKeyNewAgent.vue";
import checkwinlossweb from "./Checkwinlossweb.vue";
import ResetUsersub from "./ ResetUsersub.vue";
import createlotto from "./createlotto.vue";
import Editdomain from "./Editdomain.vue";
import Installweb from "./InstallWeb.vue";
import mockpersen from "./MockPersen.vue";
import openGameSenior from "./openGameSenior";
import changeTheme from "./Changetheme.vue";
import setdefaultoffice from "./SetDefaultOffice.vue"
// import getDomain from './GetDomain.vue'
import addIp from './Addip.vue'
import chengekey from'./Changekey.vue'
import multidomainWeb from './multidomainWeb.vue'
import InsertDomain from "./InsertDomain.vue";
export default {
  data() {
    return {
      allagent: [],
      alldatasubagent: null,
      showdata: null,
      status_senior: false,
      status_master: false,
      status_agent: false,
      status_sub_agent: false,
      status_persen: false,
      status_setpersen: false,
      status_setkeysenior: false,
      status_setallgamesenior: false,
      status_setupweb: false,
      status_sub_seniormaster: false,
      loading: false,
      status_officeweb: false,
      stats_check_line: false,
      status_setkey_new_agent: false,
      status_check_winloss_web: false,
      status_lotto: false,
      status_reset_user_sub: false,
      status_editdomain: false,
      status_install_web: false,
      status_mockpersen: false,
      status_opengame: false,
      status_changetheme: false,
      status_defaultnewoffice:false,
      status_checkdomain:false,
      status_setip:false,
      status_changekey:false,
      status_multidomain:false,
      status_insert_domain:false,
      level_user: 0,
    };
  },
  components: {
    createmaster,
    createsenior,
    createweb,
    Persen_all,
    createagent,
    Set_persen,
    Set_keysenior,
    Setting_office,
    CheckLine,
    CreateSub,
    SetKeyNewAgent,
    checkwinlossweb,
    ResetUsersub,
    createlotto,
    Editdomain,
    Installweb,
    mockpersen,
    openGameSenior,
    changeTheme,
    setdefaultoffice,
    // getDomain,
    addIp,
    chengekey,
    multidomainWeb,
    InsertDomain
    // Set_all_game_senior,
  },
  methods: {
    GetAlldata() {
      this.axios
        .get(domain + "/api/GetAllAgent")
        .then((result) => {
          result.data.forEach((element) => {
            this.allagent.push({
              value: {
                username: element,
              },
              label: element.username,
            });
          });
        })
        .catch((err) => {
          return err;
        });
    },
    CreatePo(data) {
      let body = {
        username: data,
      };
      this.loading = true;
      this.axios
        .post(domain + "/api/CreatePoAgent", body)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.msg,
              icon: "success",
            });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.msg,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    CreateSubAgent(data) {
      this.loading = true;
      let body = {
        username: data,
      };
      this.axios
        .post(domain + "/api/CreateAgent", body)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.msg,
              icon: "success",
            });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.msg,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    GetDataSub(data) {
      let body = {
        useragent: data,
      };
      this.loading = true;
      this.axios
        .post(domain + "/api/GetSubAgent", body)
        .then((result) => {
          this.loading = false;
          this.alldatasubagent = null;
          // if (result.data.code === 0) {
          this.alldatasubagent = result.data;
          // } else {
          //   alert(result.data.payload);
          // }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    addCredit(data) {
      let body = {
        useragent: data,
      };
      this.loading = true;
      this.axios
        .post(domain + "/api/AddCreditAgent", body)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.msg,
              icon: "success",
            });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.msg,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    GetCredit(data) {
      this.loading = true;
      this.axios
        .get(domain + "/api/GetBanlance?username=" + data)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: "ยอดเงินคงเหลือ : " + result.data.data.amount,
              icon: "success",
            });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.msg,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    isLogin() {
      console.log(domain);
      const token = localStorage.getItem("token");
      this.axios
        .get(domain + "/api/islogin", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          console.log(result);
          if (result.data.code === 0) {
            this.level_user = result.data.level;
            // this.$swal({
            //   title: "สำเร็จ",
            //   text: "ยอดเงินคงเหลือ : " + result.data.data.amount,
            //   icon: "success",
            // });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.message,
              icon: "error",
            });
            this.$router.push("/login_web");
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      if (process.env.NODE_ENV !== "development") {
        domain = location.origin;
        console.log(domain);
        this.isLogin();
        // this.GetAlldata();
      } else {
        domain = "http://localhost:6900";
        console.log(domain);
        this.isLogin();
        // this.GetAlldata();
      }
    } else {
      this.$router.push("/Login_web");
    }
    console.log(this.level_user);
  },
};
</script>

<style></style>
