<template>
  <div class="createsenior">
    <label for="create_Senior">ยูสตัวอย่าง Key Senior</label>
    <v-select
      v-model="selectsenior_mog"
      :options="allsenior"
      :searchable="true"
      :clearable="true"
    ></v-select>
    <label for="create_Senior">ยูสที่ต้องการ Set Key Senior</label>
    <v-select
      v-model="selectsenior_set"
      :options="allsenior"
      :searchable="true"
      :clearable="true"
    ></v-select>
    <center>
      <button class="btn btn-primary my-2" @click="Set_Key_Senior()">
        <span v-if="!loading">ยืนยัน</span>
        <div class="spinner-border" role="status" v-else>
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </center>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      username_senior: null,
      username_master: null,
      loading: false,
      allsenior: [],
      selectsenior_mog: null,
      selectsenior_set: null,
    };
  },
  methods: {
    Create_senior() {
      console.log("keysenior");
    },
    Get_master() {
      this.axios
        .get(domain + "/api/GetMaster")
        .then((result) => {
          if (result.data.code === 0) {
            result.data.payload.forEach((element) => {
              this.allsenior.push({
                value: element.username,
                label: element.username,
              });
            });
            // alert(result.data.message.message);
          } else {
            alert(result.data.msg);
          }
        })
        .catch((err) => {
          return err;
        });
    },
    Set_Key_Senior() {
      if (
        this.selectsenior_mog.value &&
        this.selectsenior_set.value &&
        this.selectsenior_set.value !== this.selectsenior_mog.value
      ) {
        let body = {
          username_mog: this.selectsenior_mog.value,
          username_set: this.selectsenior_set.value,
        };
        this.loading = true;
        this.axios
          .post(domain + "/api/Setkeysenior", body)
          .then((result) => {
            this.loading = false;
            if (result.data.code === 0) {
              this.$swal({
                title: "สำเร็จ",
                text: result.data.msg,
                icon: "success",
              });
            } else {
              this.$swal({
                title: "เกิดข้อผิดพลาด",
                text: result.data.payload.message.message,
                icon: "error",
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      } else {
        this.$swal({
          title: "เกิดข้อผิดพลาด",
          text: 'เช็คข้อมูล',
          icon: "error",
        });
      }
    },
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
      this.Get_master();
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
      this.Get_master();
    }
  },
};
</script>

<style></style>
