<template>
  <div id="MockPersen">
    <center>
      <button @click="getGameAll()" class="btn btn-primary">
        <span v-if="!loading">ดึงค่าเกม</span>
        <div class="spinner-border" role="status" v-else>
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
      <button class="btn btn-secondary mx-2" @click="getmockdata()">
        <span v-if="!loading">ดู Mockup % </span>
        <div class="spinner-border" role="status" v-else>
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </center>
    <div v-if="mock_data">
      <label for="name_data">ใส่ชื่อ</label>
      <input type="text" class="form form-control" v-model="name_data" />
      <table class="table">
        <thead>
          <tr>
            <td>ลำดับ</td>
            <td>รูปเกม</td>
            <td>ชื่อเกม</td>
            <td>
              เปอร์เซ็น
              <button class="btn btn-warning mx-2" @click="submit()">
                <span v-if="!loading">ยืนยัน</span>
                <div class="spinner-border" role="status" v-else>
                  <span class="visually-hidden">Loading...</span>
                </div>
                <!-- ยืนยัน -->
              </button>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(e, i) in mock_data" :key="i">
            <td>{{ i + 1 }}</td>
            <td>
              <img :src="e.img" alt="" style="max-height: 2rem" />
            </td>
            <td>{{ e.name }}</td>
            <td>
              <input type="number" id="share" v-model="e.persen" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="show_data">
      <div class="row justify-content-start">
        <div class="col-6" v-for="(ele, index) in show_data" :key="index">
          <center class="h5">
            {{ ele.name }}
            <button
              class="btn btn-danger btn-sm"
              @click="deletemockdata(ele.code)"
            >
              <span v-if="!loading">ลบข้อมูล</span>
              <div class="spinner-border" role="status" v-else>
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </center>
          <table class="table table-bordered border-primary text-center">
            <thead>
              <tr>
                <td>ลำดับ</td>
                <td>รูปเกม</td>
                <td>ชื่อเกม</td>
                <td>เปอร์เซ็น</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(e, i) in ele.data" :key="i">
                <td>{{ i + 1 }}</td>
                <td>
                  <img :src="e.img" alt="" style="max-height: 2rem" />
                </td>
                <td>{{ e.name }}</td>
                <td>
                  <span>{{ e.persen }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      mock_data: null,
      loading: false,
      name_data: null,
      show_data: null,
    };
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
    }
  },
  methods: {
    getGameAll() {
      this.loading = true;
      this.axios
        .get(`${domain}/api/update_mock_persen`)
        .then((result) => {
          this.show_data = null;
          this.mock_data = result.data.payload;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    submit() {
      if (this.mock_data && this.name_data) {
        this.loading = true;
        let payload = {
          name: this.name_data,
          payload: this.mock_data,
        };
        this.axios
          .post(`${domain}/api/update_mock_persen`, payload)
          .then((result) => {
            if (result.data.code === 0) {
              this.$swal({
                title: "success",
                type: "success",
              });
            } else {
              this.$swal({
                title: "error",
                type: "error",
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      } else {
        this.$swal({
          title: "error",
          type: "error",
          html: "เกิดข้อผิดพลาดกรุณากรอกข้อมูลให้ครบ",
        });
      }
    },
    getmockdata() {
      this.loading = true;
      this.axios
        .get(`${domain}/api/mock_persen`)
        .then((result) => {
          this.mock_data = null;
          this.show_data = result.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    deletemockdata(code) {
      this.loading = true;
      let payload = {
        code: code,
      };
      this.axios
        .post(`${domain}/api/delete_mock_persen`, payload)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "success",
              type: "success",
            });
          } else {
            this.$swal({
              title: "error",
              type: "error",
            });
          }
          this.getmockdata();
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
  },
};
</script>

<style></style>
