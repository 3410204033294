<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="mx-4">
   
    <input
      class="form-check-input"
      type="checkbox"
      value=""
      id="flexCheckDefault"
      v-model="status_key"
    />
    <label class="form-check-label" for="flexCheckDefault"> เปลี่ยน KEY </label>
    <div v-if="status_key">
      <label for="name_service" class="mt-2"><h6>ชื่อ service</h6></label>
      <input type="text" v-model="name_service" class="form-control" />
      <center v-if="!data_web">
        <button class="btn btn-warning mx-2 mt-2" @click="getDomain()">
          <span v-if="!loading"> ดึงข้อมูล</span>
          <div class="spinner-border" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </center>
      <div v-if="data_web">
        key ของคุณตอนนี้คือ : {{ data_web.key }} <br>
        <label for="changekey">ต้องการเปลี่ยนเป็น</label>
        <input type="text" v-model="key_set" class="form-control" />
      </div>
      <center v-if="data_web">
        <button class="btn btn-warning mx-2 mt-2" @click="submit_key()">
          <span v-if="!loading"> ยืนยันการเปลี่ยน key</span>
          <div class="spinner-border" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </center>
    </div>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      loading: false,
      data_web: null,
      status_theme: false,
      status_key: false,
      name_service: null,
      select_theme: null,
      key_set:null
    };
  },
  methods: {
    submit_theme() {
      if (this.select_theme && this.data_web) {
        if (
          this.data_web.Theme.toLowerCase() !== this.select_theme.toLowerCase()
        ) {
          this.$swal({
            title: "ยืนยันการเปลี่ยน",
            html: `เปลี่ยนธีมจาก ${this.data_web.Theme.toLowerCase()} เป็นธีม ${
              this.select_theme
            }`,
            showCancelButton: true,
          })
            .then((result) => {
              if (result.isConfirmed) {
                let body = {
                  theme: this.select_theme,
                  service: this.name_service,
                };
                this.loading = true;
                this.axios
                  .post(`${domain}/api/update_theme`, body)
                  .then((result) => {
                    this.loading = false;
                    if (result.data.code == 200) {
                      this.$swal({
                        title: "success",
                        icon: "success",
                      });
                      this.name_service = null
                    } else {
                      this.$swal({
                        title: "error",
                        text: result.data.msg,
                        icon: "error",
                      });
                      this.name_service = null
                    }
                  })
                  .catch((err) => {
                    this.name_service = null
                    this.loading = false;
                    return err;
                  });
              }
            })
            .catch((err) => {
              return err;
            });
        } else {
          this.$swal({
            title: "error",
            text: "กรุณาเลือกธีมใหม่",
            icon: "error",
          });
        }
      }
    },
    submit_key() {
      if (this.key_set && this.data_web) {
        if (
          this.data_web.key.toLowerCase() !== this.key_set.toLowerCase()
        ) {
          this.$swal({
            title: "ยืนยันการเปลี่ยน",
            html: `เปลี่ยน key : ${this.data_web.key.toLowerCase()} <br> เป็น key : ${
              this.key_set
            }`,
            showCancelButton: true,
          })
            .then((result) => {
              if (result.isConfirmed) {
                let body = {
                  key: this.key_set,
                  service: this.name_service,
                };
                this.loading = true;
                this.axios
                  .post(`${domain}/api/update_key`, body)
                  .then((result) => {
                    this.loading = false;
                    if (result.data.code == 200) {
                      this.$swal({
                        title: "success",
                        icon: "success",
                      });
                      this.name_service = null
                    } else {
                      this.$swal({
                        title: "error",
                        text: result.data.msg,
                        icon: "error",
                      });
                      this.name_service = null
                    }
                  })
                  .catch((err) => {
                    this.name_service = null
                    this.loading = false;
                    return err;
                  });
              }
            })
            .catch((err) => {
              return err;
            });
        } else {
          this.$swal({
            title: "error",
            text: "กรุณาเช็ค key",
            icon: "error",
          });
        }
      }
    },
    getDomain() {
      if (this.name_service) {
        // /api/getdomain
        this.loading = true;
        this.axios
          .get(`${domain}/api/checktheme?service=${this.name_service}`)
          .then((result) => {
            this.loading = false;
            if (result.data.code == 0) {
              this.data_web = result.data.payload;
              this.$swal({
                title: "success",
                icon: "success",
              });
            } else {
              this.$swal({
                title: "error",
                text: result.data.msg,
                icon: "error",
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      } else {
        this.$swal({
          title: "เกิดข้อผิดพลาด",
          text: "กรุณากรอกชื่อ service",
          icon: "error",
        });
      }
    },
    isValidDomain(domain) {
      // สร้าง Regular Expression สำหรับตรวจสอบโดเมนที่ถูกต้อง
      const domainRegex = /^(?!:\/\/)([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}$/;

      // ใช้ test() เพื่อตรวจสอบว่าโดเมนที่ป้อนเข้ามาถูกต้องหรือไม่
      return domainRegex.test(domain);
    },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      if (process.env.NODE_ENV !== "development") {
        domain = location.origin;
        console.log(domain);
      } else {
        domain = "http://localhost:6900";
      }
    } else {
      this.$router.push("/Login_web");
    }
  },
};
</script>

<style></style>
