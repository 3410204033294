<template>
  <div class="row justify-content-center">
    <div class="col-10">
      <div class="">
        <label for="staticEmail2" class="">Username</label>
        <input
          type="text"
          class="form-control"
          id="staticEmail2"
          v-model="username"
        />
      </div>
      <div class="">
        <label for="inputPassword2" class="">Password</label>
        <input
          type="password"
          class="form-control"
          id="inputPassword2"
          placeholder="Password"
          v-model="password"
        />
      </div>
      <div class="align-content-center mt-3 text-center">
        <button type="submit" class="btn btn-primary mb-3" @click="Login()">
          เข้าสู่ระบบ
        </button>
      </div>
    </div>
  </div>
</template>
<script>
let domain = "";
export default {
  data() {
    return {
      username: null,
      password: null,
    };
  },
  methods: {
    Login() {
      if (this.username !== null && this.password !== null) {
        this.axios
          .get(
            domain +
              "/api/login?username=" +
              this.username +
              "&password=" +
              this.password
          )
          .then(async (result) => {
            if (result.data.msg === "success") {
              this.$swal({
                title: "สำเร็จ",
                text: result.data.msg,
                icon: "success",
              });
              this.$store.commit("SET_LOGIN_STATUS", true);
              localStorage.setItem("token", result.data.data.token);
              this.$router.push("/Main_Web");
              // this.CheckStatus();
            } else {
              this.$swal({
                title: "เกิดข้อผิดพลาด",
                text: result.data.msg,
                icon: "error",
              });
            }
          });
      }
    },
    CheckStatus() {
      this.axios.get(domain + "/api/status").then((result) => {
        console.log(result.data);
      });
    },
  },
  mounted() {
    console.log(process.env.NODE_ENV);
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
      // this.getDatatest();
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
    }
    console.log(domain);
  },
};
</script>

<style></style>
