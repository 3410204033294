<template>
  <div id="CheckLine">
    <div class="row justify-content-center text-left mt-3">
      <v-col class="col-12"
        ><button class="btn btn-primary mx-2" @click="userLoginLine()">
          <span v-if="!loading"> เช็คข้อมูล</span>
          <div class="spinner-border" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <button class="btn btn-warning" @click="checkNumber()">
          <span v-if="!loadingnumber"> เช็ครหัสยืนยัน Line</span>
          <div class="spinner-border" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </button></v-col
      >
    </div>
    <div v-for="item in datacheck" :key="item.id" class="mt-3">
      <span :class="item.type === 'web' ? 'text-danger' : 'text-warning'">
        {{ item.type === "web" ? "หน้าระบบ" : "หลังบ้าน" }}</span
      >
      : {{ item.name }} =
      <span :class="item.length === 400 ? 'text-danger' : 'text-success'">
        {{ item.length }} / 400
        <span v-if="item.length === 400"> เต็ม </span>
        <span v-else> ว่าง {{ 400 - item.length }}</span>
      </span>
    </div>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      datacheck: null,
      loading: false,
      loadingnumber: false,
    };
  },
  methods: {
    getCountLine() {
      this.axios
        .get(domain + "/api/check_count_line")
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.datacheck = result.data.payload;
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    userLoginLine() {
      this.$swal({
        title: "LoginLine",
        html:
          '<div class="text-left"><span>Email</span><input id="swal-input1" class="swal2-input"><br>' +
          '<span>Password</span><input id="swal-input2" class="swal2-input" type="password"></div>',
        // inputAttributes: {
        //   autocapitalize: "off",
        // },
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return [
            document.getElementById("swal-input1").value,
            document.getElementById("swal-input2").value,
          ];
        },
      })
        .then((result) => {
          console.log(result);
          this.checkCountLine(
            document.getElementById("swal-input1").value,
            document.getElementById("swal-input2").value
          );
        })
        .catch((err) => {
          return err;
        });
    },
    checkCountLine(email, pass) {
      this.loading = true;
      let body = {
        username: email,
        password: pass,
      };
      this.axios
        .post(domain + "/api/get_count_line", body)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.msg,
              icon: "success",
            });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    checkNumber() {
      this.loadingnumber = true;
      this.axios
        .get(domain + "/api/get_number_line")
        .then((result) => {
          this.loadingnumber = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.payload,
              icon: "success",
            });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loadingnumber = false;
          return err;
        });
    },
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
      this.getCountLine();
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
      this.getCountLine();
    }
  },
};
</script>

<style></style>
