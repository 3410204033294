<template>
  <div id="SetKeyNewAgent">
    <v-select
      v-model="selectDataAgent"
      :options="allDataAgent"
      :searchable="true"
      :clearable="true"
      class="my-2"
    ></v-select>
    <center>
      <button class="btn btn-primary" @click="SetAgent()">เพิ่ม</button>
    </center>
    <div v-if="group_Agent.length > 0">
      <span> {{ group_Agent }}</span>
      <button @click="group_Agent = []" class="btn btn-danger btn-sm">
        Clear
      </button>
      <br />
      <button class="btn btn-success" @click="Submit()">
        <span v-if="!loading">ยืนยัน</span>
        <div class="spinner-border" role="status" v-else>
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      data: null,
      allDataAgent: [],
      selectDataAgent: null,
      group_Agent: [],
      loading: false,
    };
  },
  methods: {
    GetAlldata() {
      this.axios
        .get(domain + "/api/GetAllAgent")
        .then((result) => {
          result.data.forEach((element) => {
            this.allDataAgent.push({
              value: {
                username: element,
              },
              label: element.username,
            });
          });
        })
        .catch((err) => {
          return err;
        });
    },
    SetAgent() {
      let uniqueSet = new Set(this.group_Agent);
      if (!uniqueSet.has(this.selectDataAgent.value.username.username)) {
        this.group_Agent.push(this.selectDataAgent.value.username.username);
      }
    },
    Submit() {
      if (this.group_Agent) {
        this.loading = true;
        let body = {
          user: this.group_Agent,
        };
        this.axios
          .post(domain + "/api/SetKeyAgent", body, { timeout: 0 })
          .then((result) => {
            this.loading = false;
            if (result.data.code === 0) {
              this.$swal({
                title: "สำเร็จ",
                text: result.data.msg,
                icon: "success",
              });
            } else {
              this.$swal({
                title: "เกิดข้อผิดพลาด",
                text: result.data.code,
                icon: "error",
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      }
    },
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
      this.GetAlldata();
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
      this.GetAlldata();
    }
  },
};
</script>

<style></style>
