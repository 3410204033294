<template>
  <div class="form-check container mt-3">
    <!-- <div id="select1">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_senior"
      />
      <label class="form-check-label" for="flexCheckDefault">
        สร้าง senior
      </label>
      <div v-if="status_senior" class="row justify-content-center">
        <div class="col-10">
          <createsenior />
        </div>
      </div>
    </div> -->
    <!-- <div id="select2">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_master"
      />
      <label class="form-check-label" for="flexCheckDefault">
        สร้าง master
      </label>
      <div v-if="status_master" class="row justify-content-center">
        <div class="col-10">
          <createmaster />
        </div>
      </div>
    </div> -->
    <!-- <div id="select3">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_agent"
      />
      <label class="form-check-label" for="flexCheckDefault">
        สร้าง agent
      </label>
      <div v-if="status_agent">
        <createweb />
      </div>
    </div> -->
    <!-- <div id="select4"> -->
      <!-- <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_sub_agent"
      />
      <label class="form-check-label" for="flexCheckDefault">
        สร้าง sub_agent
      </label>
      <div v-if="status_sub_agent" class="row justify-content-center"> -->
        <div class="col-10">
          <center>เลือก agent</center>
          <v-select
            v-model="showdata"
            :options="allagent"
            :searchable="true"
            :clearable="true"
            class="my-2"
          ></v-select>
          <table class="table" v-if="showdata">
            <thead>
              <tr class="text-center">
                <!-- <th scope="col">#</th> -->
                <th scope="col">ชื่อ agent</th>
                <th scope="col">ชนิด</th>
                <th scope="col">การทำงาน</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in showdata.value"
                :key="index"
                class="text-center"
              >
                <!-- <th scope="row">{{ index + 1 }}</th> -->
                <td>{{ item.username }}</td>
                <td>{{ item.type }}</td>
                <td>
                  <button
                    class="btn btn-success mx-1"
                    @click="CreatePo(item.username)"
                    :disabled="loading"
                  >
                    <span v-if="!loading">สร้างสิทธิ์</span>
                    <div class="spinner-border" role="status" v-else>
                      <span class="visually-hidden">Loading...</span>
                    </div></button
                  ><button
                    class="btn btn-primary mx-1"
                    @click="CreateSubAgent(item.username)"
                    :disabled="loading"
                  >
                    <span v-if="!loading">สร้างยูส sup</span>
                    <div class="spinner-border" role="status" v-else>
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                  <button
                    class="btn btn-danger mx-1"
                    @click="GetDataSub(item.username)"
                    :disabled="loading"
                    data-bs-target="#exampleModal"
                    data-bs-toggle="modal"
                  >
                    <span v-if="!loading">ดูข้อมูล</span>
                    <div class="spinner-border" role="status" v-else>
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                  <button
                    class="btn btn-warning mx-1"
                    @click="addCredit(item.username)"
                    :disabled="loading"
                  >
                    <span v-if="!loading">เติมเครดิต</span>
                    <div class="spinner-border" role="status" v-else>
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                  <button
                    class="btn btn-warning mx-1"
                    @click="GetCredit(item.username)"
                    :disabled="loading"
                  >
                    <span v-if="!loading">เช็คเครดิต</span>
                    <div class="spinner-border" role="status" v-else>
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    UserSubagent
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body" v-if="alldatasubagent">
                  <div v-for="(item, index) in alldatasubagent" :key="index">
                    username : {{ item.username }} <br />
                    password : {{ item.password }}
                    <hr />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- </div> -->
    <!-- </div> -->
    <!-- <div id="select6">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_setpersen"
      />
      <label class="form-check-label" for="flexCheckDefault">
        Setup เปอร์เซ็น
      </label>
      <div v-if="status_setpersen">
        <Set_persen />
      </div>
    </div> -->
    <!-- <div id="select5">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_persen"
      />
      <label class="form-check-label" for="flexCheckDefault">
        เช็คเปอร์เซ็น
      </label>
      <div v-if="status_persen">
        <Persen_all />
      </div>
    </div> -->
  </div>
</template>

<script>
let domain = "";
// import createmaster from "./CreateMaster.vue";
// import createsenior from "./CreateSenior.vue";
// import createweb from "./CreateWeb.vue";
// import Persen_all from "./Persen_all.vue";
// import Set_persen from './Set_persen.vue'
export default {
  data() {
    return {
      allagent: [],
      alldatasubagent: null,
      showdata: null,
      status_senior: false,
      status_master: false,
      status_agent: false,
      status_sub_agent: false,
      status_persen: false,
      status_setpersen: false,
      loading: false,
    };
  },
  components: {
    // createmaster,
    // createsenior,
    // createweb,
    // Persen_all,
    // Set_persen
  },
  methods: {
    GetAlldata() {
      this.axios
        .get(domain + "/api/GetAllAgent")
        .then((result) => {
          result.data.forEach((element) => {
            this.allagent.push({
              value: {
                username: element,
              },
              label: element.username,
            });
          });
        })
        .catch((err) => {
          return err;
        });
    },
    CreatePo(data) {
      let body = {
        username: data,
      };
      this.loading = true;
      this.axios
        .post(domain + "/api/CreatePoAgent", body)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.msg,
              icon: "success",
            });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.msg,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    CreateSubAgent(data) {
      this.loading = true;
      let body = {
        username: data,
      };
      this.axios
        .post(domain + "/api/CreateAgent", body)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.msg,
              icon: "success",
            });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.msg,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    GetDataSub(data) {
      let body = {
        useragent: data,
      };
      this.loading = true;
      this.axios
        .post(domain + "/api/GetSubAgent", body)
        .then((result) => {
          this.loading = false;
          this.alldatasubagent = null;
          // if (result.data.code === 0) {
          this.alldatasubagent = result.data;
          // } else {
          //   alert(result.data.payload);
          // }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    addCredit(data) {
      let body = {
        useragent: data,
      };
      this.loading = true;
      this.axios
        .post(domain + "/api/AddCreditAgent", body)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.msg,
              icon: "success",
            });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.msg,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    GetCredit(data) {
      this.loading = true;
      this.axios
        .get(domain + "/api/GetBanlance?username=" + data)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: "ยอดเงินคงเหลือ : " + result.data.data.amount,
              icon: "success",
            });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.msg,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
  },
  mounted() {
    if (this.$store.state.auth.isLoggedIn) {
      if (process.env.NODE_ENV !== "development") {
        domain = location.origin;
        console.log(domain);
        this.GetAlldata();
      } else {
        domain = "http://localhost:6900";
        console.log(domain);
        this.GetAlldata();
      }
    } else {
      this.$router.push("/Login_web");
    }
  },
};
</script>

<style></style>
