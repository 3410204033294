<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div v-if="!domain_web_status">
      <label for="name_service" class="mt-2"><h6>ชื่อ service</h6></label>
      <input
        type="text"
        v-model="name_service"
        class="form-control"
        :disabled="domain_web && domain_office"
      />
      <div v-if="domain_web">
        <label for="domain_web" class="mt-2"
          ><h6>โดเมนเว็บ (ไม่มี https)</h6></label
        ><input type="text" v-model="domain_web" class="form-control" />
      </div>
      <div v-if="domain_office">
        <label for="domain_office" class="mt-2">
          <h6>โดเมน office (ไม่มี https)</h6></label
        >
        <input type="text" v-model="domain_office" class="form-control" />
      </div>
      <div v-if="domain_hydra">
        <label for="domain_office" class="mt-2">
          <h6>โดเมน hydra (ไม่มี https)</h6></label
        >
        <input type="text" v-model="domain_hydra" class="form-control" />
      </div>
      <!-- <div class="form-check mt-2" v-if="domain_web && domain_office">
      <input
        class="form-check-input"
        type="checkbox"
        id="flexCheckDefault"
        v-model="domain_status"
      />
      <span v-if="!domain_status">โดเมนไม่ได้อยู่กับเรา</span>
      <span v-else>โดเมนอยู่กับเรา</span>
    </div> -->
      <div class="form-check mt-2" v-if="domain_web && domain_office">
        <input
          class="form-check-input"
          type="checkbox"
          id="flexCheckDefault"
          v-model="domain_redirect_status"
        />
        <div v-if="!domain_redirect_status">
          <span>ไม่ต้องการ redirect โดเมนเก่า</span>
        </div>
        <div v-else>
          <span>ต้องการ redirect โดเมนเก่า</span>
          <div v-if="domain_web">
            <label for="domainweb_old">จากโดเมนหน้าระบบ</label>
            <input
              type="text"
              class="form-control"
              disabled
              v-model="domainweb_old"
            />
            <label for="domain_new">ไปยัง</label>
            <input
              type="text"
              class="form-control"
              v-model="domain_web"
              disabled
            />
          </div>
          <div v-if="domain_office" class="my-2">
            <label for="domainweb_old">จากโดเมนหลังบ้าน</label>
            <input
              type="text"
              class="form-control"
              disabled
              v-model="domainoffice_old"
            />
            <label for="domain_new">ไปยัง</label>
            <input
              type="text"
              class="form-control"
              v-model="domain_office"
              disabled
            />
          </div>
        </div>
      </div>
      <center>
        <button
          class="btn btn-warning mx-2 mt-2"
          @click="getDomain()"
          v-if="!domain_web && !domain_office"
        >
          <span v-if="!loading"> ดึงข้อมูล domain</span>
          <div class="spinner-border" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <button
          v-if="domain_web && domain_office"
          class="btn btn-primary mt-2"
          @click="Submit()"
        >
          <span v-if="!loading"> ยืนยัน</span>
          <div class="spinner-border" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </center>
    </div>
    <div class="mx-4">
      <label for="เปลี่ยนเฉพาะโดเมนหน้าระบบ">เปลี่ยนเฉพาะโดเมนหน้าระบบ</label>
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="domain_web_status"
      />

      <div v-if="domain_web_status">
        <input
          type="text"
          v-model="name_service"
          class="form-control"
          :disabled="domain_web && domain_office"
        />
        <center>
          <button
            class="btn btn-warning mx-2 mt-2"
            @click="getDomain()"
            v-if="!domain_web && !domain_office"
          >
            <span v-if="!loading"> ดึงข้อมูล domain</span>
            <div class="spinner-border" role="status" v-else>
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
        </center>
        <div v-if="domain_web">
          <label for="domain_web" class="mt-2"
            ><h6>โดเมนเว็บ (ไม่มี https)</h6></label
          ><input type="text" v-model="domain_web" class="form-control" />
          <input
            class="form-check-input"
            type="checkbox"
            id="flexCheckDefault"
            v-model="domain_redirect_status"
          />
          <div v-if="!domain_redirect_status">
            <span>ไม่ต้องการ redirect โดเมนเก่า</span>
          </div>
          <div v-else>
            <label for="domainweb_old">จากโดเมนหน้าระบบ</label>
            <input
              type="text"
              class="form-control"
              disabled
              v-model="domainweb_old"
            />
            <label for="domain_new">ไปยัง</label>
            <input
              type="text"
              class="form-control"
              v-model="domain_web"
              disabled
            />
          </div>
        </div>
        <center>
          <button
            v-if="domain_web"
            class="btn btn-primary mt-2"
            @click="Submitfront()"
          >
            <span v-if="!loading"> ยืนยัน</span>
            <div class="spinner-border" role="status" v-else>
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
        </center>
      </div>
    </div>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      domain_web: null,
      domain_office: null,
      domain_hydra: null,
      name_service: null,
      status_domain: false,
      domain_status: true,
      loading: false,
      domain_redirect_status: true,
      domainweb_old: null,
      domainoffice_old: null,
      domain_hydra_old: null,
      domain_web_status: false,
      url: null,
    };
  },
  methods: {
    getDomain() {
      if (this.name_service) {
        // /api/getdomain
        this.loading = true;
        this.axios
          .get(`${domain}/api/getdomain?service=${this.name_service}`)
          .then((result) => {
            this.loading = false;
            if (result.data.code == 0) {
              this.domain_web = result.data.payload.domain_web;
              this.domain_office = result.data.payload.domain_office;
              this.domain_hydra = result.data.payload.domain_hydra;
              this.domainweb_old = result.data.payload.domain_web;
              this.domainoffice_old = result.data.payload.domain_office;
              this.domain_hydra_old = result.data.payload.domain_hydra;
              this.$swal({
                title: "success",
                icon: "success",
              });
            } else {
              this.$swal({
                title: "error",
                text: result.data.msg,
                icon: "error",
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      } else {
        this.$swal({
          title: "เกิดข้อผิดพลาด",
          text: "กรุณากรอกชื่อ service",
          icon: "error",
        });
      }
    },
    Submit() {
      // if (
      //   this.domain_web !== this.domainweb_old &&
      //   this.domain_office !== this.domainoffice_old
      // ) {
      // if (
      //   this.isValidDomain(this.domain_web) &&
      //   this.isValidDomain(this.domain_office)
      // ) {
      this.loading = true;
      if (this.domain_hydra != null) {
        this.url = `${domain}/api/editdomain?domain_web=${this.domain_web.toLowerCase()}&domain_office=${this.domain_office.toLowerCase()}&service=${
          this.name_service
        }&bypass=${this.domain_status}&domainweb_old=${
          this.domain_redirect_status ? this.domainweb_old : null
        }&domainoffice_old=${
          this.domain_redirect_status ? this.domainoffice_old : null
        }&domain_hydra=${this.domain_hydra.toLowerCase()}`;
      } else {
        this.url = `${domain}/api/editdomain?domain_web=${this.domain_web.toLowerCase()}&domain_office=${this.domain_office.toLowerCase()}&service=${
          this.name_service
        }&bypass=${this.domain_status}&domainweb_old=${
          this.domain_redirect_status ? this.domainweb_old : null
        }&domainoffice_old=${
          this.domain_redirect_status ? this.domainoffice_old : null
        }`;
      }
      this.axios
        .get(this.url)
        .then((result) => {
          this.loading = false;
          if (result.data.code == 0) {
            this.$swal({
              title: "success",
              icon: "success",
            });
          } else {
            this.$swal({
              title: "error",
              text: result.data.msg,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
      // } else {
      //   this.$swal({
      //     title: "เกิดข้อผิดพลาด",
      //     text: "โปรดเช็คโดเมนให้ถูกต้อง",
      //     icon: "error",
      //   });
      // }
      // } else {
      //   this.$swal({
      //     title: "เกิดข้อผิดพลาด",
      //     text: "โดเมนไม่ถูกเปลี่ยนแปลง",
      //     icon: "error",
      //   });
      // }
    },
    Submitfront() {
      if (this.domain_web) {
        this.loading = true;
        let body = this.domain_redirect_status
          ? {
              domain_web: this.domain_web,
              domain_web_old: this.domainweb_old,
              service: this.name_service,
            }
          : {
              domain_web: this.domain_web,
              service: this.name_service,
              redirect_domain: false,
            };
        this.axios
          .post(`${domain}/api/editdomainweb`, body)
          .then((result) => {
            if (result.data.code == 0) {
              this.$swal({
                title: "success",
                icon: "success",
              });
            } else {
              this.$swal({
                title: "error",
                text: result.data.msg,
                icon: "error",
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      } else {
        this.$swal({
          title: "เกิดข้อผิดพลาด",
          text: "โดเมนไม่ถูกเปลี่ยนแปลง",
          icon: "error",
        });
      }
    },
    isValidDomain(domain) {
      // สร้าง Regular Expression สำหรับตรวจสอบโดเมนที่ถูกต้อง
      const domainRegex = /^(?!:\/\/)([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}$/;

      // ใช้ test() เพื่อตรวจสอบว่าโดเมนที่ป้อนเข้ามาถูกต้องหรือไม่
      return domainRegex.test(domain);
    },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      if (process.env.NODE_ENV !== "development") {
        domain = location.origin;
        console.log(domain);
      } else {
        domain = "http://localhost:6900";
      }
    } else {
      this.$router.push("/Login_web");
    }
  },
};
</script>

<style></style>
