import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CreateWeb from "../views/CreateWeb.vue";
import CreateSubAgent from "../views/CreateSubAgent.vue";
import CreateSenior from "../views/CreateSenior.vue";
import CreateMaster from "../views/CreateMaster.vue";
import Persen_all from "../views/Persen_all.vue";
import Set_persen from '../views/Set_persen.vue';
import Login_web from '../components/Login_web.vue';
import Main_web from '../views/MainWeb.vue';
import Setting_office from '../views/SettingOffice.vue';
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/CreateWeb",
    name: "CreateWeb",
    component: CreateWeb,
  },
  {
    path: "/CreateSubAgent",
    name: "CreateSubAgent",
    component: CreateSubAgent,
  },
  {
    path: "/CreateSenior",
    name: "CreateSenior",
    component: CreateSenior,
  },
  {
    path: "/CreateMaster",
    name: "CreateMaster",
    component: CreateMaster,
  },
  {
    path: "/Persen_all",
    name: "Persen_all",
    component: Persen_all,
  },
  {
    path: "/Set_persen",
    name: "Set_persen",
    component: Set_persen,
  },
  {
    path: "/Login_web",
    name: "Login_web",
    component: Login_web,
  },
  {
    path: "/Main_web",
    name: "Main_web",
    component: Main_web,
  },
  {
    path:"/Setting_office",
    name:"Setting_office",
    component: Setting_office,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
