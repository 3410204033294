<template>
  <div>
    <center>
      <h3>สร้างงาน</h3>
    </center>
    <div>
      <button class="btn btn-primary btn-sm" @click="set_call_back()">
        <span v-if="!loading"> เพิ่ม callback agent</span>
        <div class="spinner-border" role="status" v-else>
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
      <button class="btn btn-warning btn-sm mx-2" @click="create_user_lotto()">
        <span v-if="!loading"> สร้างยูส ceo</span>
        <div class="spinner-border" role="status" v-else>
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
      <button class="btn btn-info btn-sm">แอดข้อมูล</button>
    </div>
  </div>
</template>

<script>
let domain = "";
export default {
  name: "ProjectRobotsCreatelotto",
  data() {
    return {
      allsenior: [],
      allmaster: [],
      allagent: [],
      allgrouplotto: [],
      selectsenior: null,
      selectmaster: null,
      selectgrouplotto: null,
      showdata: null,
      office_url: null,
      persen: [0, 0, 0],
      loading: false,
      listjoblotto: null,
    };
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
      // this.Get_data();
      // this.Get_listJob();
      // this.Get_master();
      // this.getmaster();
      // this.GetAlldata();
      // this.Get_Group_Lotto();
      // this.Get_list_job_lto();
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
      // this.Get_data();
      // this.Get_listJob();
      // this.Get_master();
      // this.getmaster();
      // this.GetAlldata();
      // this.Get_Group_Lotto();
      // this.Get_list_job_lto();
    }
  },

  methods: {
    Get_master() {
      this.axios
        .get(domain + "/api/GetMaster")
        .then((result) => {
          if (result.data.code === 0) {
            result.data.payload.forEach((element) => {
              this.allsenior.push({
                value: element.username,
                label: element.username,
              });
            });
            // alert(result.data.message.message);
          } else {
            alert(result.data.msg);
          }
        })
        .catch((err) => {
          return err;
        });
    },
    getmaster() {
      this.axios
        .get(domain + "/api/GetUserMaster")
        .then((result) => {
          // this.allmaster = result.data;
          result.data.forEach((element) => {
            this.allmaster.push({
              value: element.username,
              label: element.username,
            });
          });
        })
        .catch((err) => {
          return err;
        });
    },
    GetAlldata() {
      this.axios
        .get(domain + "/api/GetAllAgent")
        .then((result) => {
          result.data.forEach((element) => {
            this.allagent.push({
              value: {
                username: element,
              },
              label: element.username,
            });
          });
        })
        .catch((err) => {
          return err;
        });
    },
    Push_data() {
      if (
        this.selectsenior &&
        this.selectmaster &&
        this.showdata &&
        this.persen[0] > 0 &&
        this.persen[1] > 0 &&
        this.persen[2] > 0 &&
        this.selectgrouplotto
      ) {
        this.loading = true;
        const agent = this.showdata.value.username.username;
        let body = {
          senior: this.selectsenior.value,
          master: this.selectmaster.value,
          agent: agent.split("agent")[0] + "lotto",
          persen: this.persen,
          office_url: this.office_url,
          create_use: false,
          set_api: false,
          create_use_sub: false,
          group: this.selectgrouplotto.value,
        };
        this.axios
          .post(domain + "/api/createjoblotto", body)
          .then((result) => {
            console.log(result.data);
            if (result.data.code === 0) {
              this.$swal({
                title: "สำเร็จ",
                icon: "success",
              });
            } else {
              this.$swal({
                title: "เกิดข้อผิดพลาด",
                icon: "error",
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      } else {
        this.$swal({
          title: "เกิดข้อผิดพลาด",
          text: "ตรวจสอบข้อมูล",
          icon: "error",
        });
      }
    },
    Get_Group_Lotto() {
      this.axios
        .get(domain + "/api/getgrouplotto")
        .then((result) => {
          // this.allmaster = result.data;
          result.data.payload.forEach((element) => {
            this.allgrouplotto.push({
              value: element,
              label: element,
            });
          });
          console.log(this.allgrouplotto);
        })
        .catch((err) => {
          return err;
        });
    },
    Get_list_job_lto() {
      this.axios
        .get(domain + "/api/getlistjoblotto")
        .then((result) => {
          this.listjoblotto = result.data.payload;
        })
        .catch((err) => {
          return err;
        });
    },
    Create_Lotto(item) {
      console.log(item);
      let body = item;
      this.axios
        .post(domain + "/api/createuselotto", body)
        .then((result) => {
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              icon: "success",
            });
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              icon: "error",
              text: result.data.data,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    set_call_back() {
      this.$swal({
        title: "ใส่ user&pass ของหวย",
        html:
          '<div class="text-left"><span>username</span><input id="swal-input1" class="swal2-input"><br>' +
          '<span>password</span><input id="swal-input2" class="swal2-input" type="password"></div><br><span>office</span><input id="swal-input3" class="swal2-input" type="text"></div>',
        // inputAttributes: {
        //   autocapitalize: "off",
        // },
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return [
            document.getElementById("swal-input1").value,
            document.getElementById("swal-input2").value,
            document.getElementById("swal-input3").value,
          ];
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.axios
              .get(
                `${domain}/api/setcallback?username=${
                  document.getElementById("swal-input1").value
                }&pass=${document.getElementById("swal-input2").value}&office=${
                  document.getElementById("swal-input3").value
                }`
              )
              .then((result) => {
                if (result.data.code === 0) {
                  this.loading = false;
                  this.$swal({
                    title: "สำเร็จ",
                    icon: "success",
                  });
                } else {
                  this.loading = false;
                  this.$swal({
                    title: "เกิดข้อผิดพลาด",
                    icon: "error",
                    text: result.data.data,
                  });
                }
                this.loading = false;
              })
              .catch((err) => {
                this.loading = false;
                return err;
              });
          }
        })
        .catch((err) => {
          return err;
        });
    },
    create_user_lotto() {
      this.$swal({
        title: "ใส่ user&pass ของหวย",
        html:
          '<div class="text-left"><span>username</span><input id="swal-input1" class="swal2-input"><br>' +
          '<span>password</span><input id="swal-input2" class="swal2-input" type="password"></div>',
        // inputAttributes: {
        //   autocapitalize: "off",
        // },
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return [
            document.getElementById("swal-input1").value,
            document.getElementById("swal-input2").value,
          ];
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.axios
              .get(
                `${domain}/api/createceo?username=${
                  document.getElementById("swal-input1").value
                }&pass=${document.getElementById("swal-input2").value}`
              )
              .then((result) => {
                if (result.data.code === 0) {
                  this.loading = false;
                  this.$swal({
                    title: "สำเร็จ",
                    icon: "success",
                    html:
                      "user : " +
                      result.data.data.username +
                      "<br>password : " +
                      result.data.data.password,
                  });
                } else {
                  this.$swal({
                    title: "เกิดข้อผิดพลาด",
                    icon: "error",
                    text: result.data.data,
                  });
                }
                this.loading = false;
              })
              .catch((err) => {
                this.loading = false;
                return err;
              });
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
