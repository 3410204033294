<template>
  <div class="row g-3 justify-content-center">
    <div class="col-10">
      <div><center>Create Agent</center></div>
      <!-- <form> -->
      <div class="mb-3">
        <label for="usermater" class="form-label"
          >เลือก Master ที่ต้องการสร้าง</label
        >
        <v-select
          v-model="selectmaster"
          :options="allmaster"
          :searchable="true"
          :clearable="true"
        ></v-select>
        <!-- <div v-if="selectmaster">Master : {{ selectmaster.value }}</div> -->
      </div>
      <div class="mb-3">
        <label for="useragent" class="form-label">Useragent</label>
        <input
          type="useragent"
          class="form-control"
          id="useragent"
          v-model="useragent"
        />
      </div>
      <div class="mb-3">
        <label for="prefix" class="form-label">Prefix</label>
        <input
          type="prefix"
          class="form-control"
          id="prefix"
          v-model="prefix"
        />
      </div>
      <div class="mb-3">
        <label for="prefix" class="form-label">Whitelist IP</label>
        <!-- <input type="prefix" class="form-control" id="prefix" v-model="whitelist_ip"  /> -->
        <select
          class="form-select"
          aria-label="Default select example"
          v-model="whitelist_ip"
        >
          <option
            selected
            v-for="(data, index) in data_whitelist_ip"
            :key="index"
            :value="data.whitelistip"
          >
            {{ data.name }}
          </option>
        </select>
      </div>
      <center>
        <button
          class="btn btn-primary"
          @click="SubmitData()"
          :disabled="loading"
        >
          <span v-if="!loading">ยืนยัน</span>
          <div class="spinner-border" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </center>
    </div>
  </div>
  <!-- </form> -->
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      allmaster: [],
      selectmaster: null,
      useragent: null,
      prefix: null,
      whitelist_ip: null,
      data_whitelist_ip: null,
      loading: false,
    };
  },
  methods: {
    getmaster() {
      this.axios
        .get(domain + "/api/GetUserMaster")
        .then((result) => {
          // this.allmaster = result.data;
          result.data.forEach((element) => {
            this.allmaster.push({
              value: element.username,
              label: element.username,
            });
          });
        })
        .catch((err) => {
          return err;
        });
    },
    SubmitData() {
      let data = {
        usermaster: this.selectmaster.value.toLowerCase(),
        useragent: this.useragent.toLowerCase(),
        prefix_agent: this.prefix.toLowerCase(),
        whitelist_ip: this.whitelist_ip,
      };
      if (
        this.selectmaster &&
        this.whitelist_ip &&
        this.prefix &&
        this.useragent
      ) {
        this.loading = true;
        this.axios
          .post(domain + "/api/CreateMultiAccount", data)
          .then((result) => {
            this.loading = false;
            if (result.data.code === 0) {
              this.$swal({
                title: "สำเร็จ",
                html:
                  "รหัสผ่าน : " +
                  result.data.payload.password +
                  "<br>คีย์ AGENT : " +
                  result.data.payload.api_key +
                  "<br>Prefix : " +
                  result.data.payload.prefix,
                icon: "success",
              });
            } else {
              this.$swal({
                title: "เกิดข้อผิดพลาด",
                text: result.data.data.message.message,
                icon: "error",
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      } else {
        alert("กรุณากรอกข้อมูล");
      }
    },
    GetWhiteIP() {
      this.axios
        .get(domain + "/api/GetWhiteIP")
        .then(async (result) => {
          this.data_whitelist_ip = result.data.payload.whitelist;
        })
        .catch((err) => {
          return err;
        });
    },
  },
  async mounted() {
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
      await this.getmaster();
      await this.GetWhiteIP();
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
      await this.getmaster();
      await this.GetWhiteIP();
    }
  },
};
</script>

<style></style>
