<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div id="Checkwinlossweb">
    <!-- จัดการหน้าเว็บ -->
    <VueDatePicker
      v-model="date"
      ignore-time-validation
      range
      :enable-time-picker="false"
      utc
    />
    <center class="mt-2">
      <button @click="submit()" class="btn btn-primary">
        <span v-if="!loading">ตรวจสอบ</span>
        <div class="spinner-border" role="status" v-else>
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </center>
    <div v-for="item in dataall" :key="item.name">
      <div class="mt-2" v-if="item.status === false">
        <div class="card card-primary">
          <div class="card-body">
            หลังบ้าน: {{ item.office }} <br />
            เว็บ: {{ item.web }} <br />
            เอเจ้น: {{ item.agent }} <br />
            winloss: {{ item.winloss }}
            <br />
            สถานะ:
            <span
              :class="item.status === false ? 'text-danger' : 'text-success'"
              >{{
                item.status === false ? "ยังไม่เปิดเว็บ" : "เปิดเว็บแล้ว"
              }}</span
            >
            <br />
            การใช้งานเว็บ:
            <span
              :class="item.winloss > 1000 ? 'text-danger' : 'text-success'"
              >{{
                item.winloss > 1000
                  ? "เว็บมีการใช้งานเครดิต"
                  : "เว็บยังไม่มีการใช้งานเครดิตถึงขั้นต่ำ"
              }}</span
            >
            <br />
            <div class="row">
              <div class="col-4">
                <label for="date_srat">เลือกวันที่เปิด</label>
                <VueDatePicker
                  v-model="date_start"
                  ignore-time-validation
                  :enable-time-picker="false"
                  utc
                />
                <button
                  class="btn btn-success my-2"
                  @click="submit_start(item.agent)"
                >
                  <span v-if="!loading">ยืนยัน</span>
                  <div class="spinner-border" role="status" v-else>
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      dataall: null,
      date: null,
      loading: false,
      date_start: null,
    };
  },
  methods: {
    submit() {
      const utcYear_start = new Date(this.date[0]).getUTCFullYear();
      const utcMonth_start = new Date(this.date[0]).getUTCMonth();
      const utcDay_start = new Date(this.date[0]).getUTCDate();
      const utcYear_end = new Date(this.date[1]).getUTCFullYear();
      const utcMonth_end = new Date(this.date[1]).getUTCMonth();
      const utcDay_end = new Date(this.date[1]).getUTCDate();
      const midnightUTCstart = new Date(
        utcYear_start,
        utcMonth_start,
        utcDay_start,
        0,
        0,
        0,
        0
      );
      const midnightUTCend = new Date(
        utcYear_end,
        utcMonth_end,
        utcDay_end,
        0,
        0,
        0,
        0
      );
      const currentDaystart = Math.floor(
        new Date(midnightUTCstart).getTime() / 1000
      );
      const currentDayend = Math.floor(
        new Date(midnightUTCend).getTime() / 1000
      );
      this.Get_master(currentDaystart, currentDayend);
    },
    Get_master(start, end) {
      this.loading = true;
      this.axios
        .get(
          `${domain}/api/get_check_web?start_date_unix=${start}&end_date_unix=${end}`
        )
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.dataall = result.data.payload;
          } else {
            alert(result.data.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    submit_start(name) {
      if (this.date_start) {
        this.loading = true;
        this.axios
          .get(
            `${domain}/api/start_web?open_web=${this.date_start}&web=${name}`
          )
          .then(async (result) => {
            if (result.data.code === 0) {
              await this.submit();
              await this.$swal({
                title: "สำเร็จ",
                text: result.data.msg,
                icon: "success",
              });
            } else {
              this.$swal({
                title: "เกิดข้อผิดพลาด",
                text: result.data,
                icon: "error",
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      } else {
        this.$swal({
          title: "เกิดข้อผิดพลาด",
          text: "กรุณาเช็ควันที่เปิด",
          icon: "error",
        });
      }
    },
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
    }
  },
};
</script>
<!-- <script setup>
import { ref } from "vue";

const date = ref();

// Today with the time 00:00
// Without ignoreTimeValidation, you will not be able to select the date
const maxDate = ref(
  new Date(2022, new Date().getMonth(), new Date().getDate())
);
console.log(maxDate);
</script> -->
<style></style>
