<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <label for="name_service" class="mt-2"><h6>เลือก master</h6></label>
    <v-select
      v-model="selectmaster"
      :options="allmaster"
      :searchable="true"
      :clearable="true"
      @open="Get_master"
    ></v-select>
    <label for="name_service" class="mt-2"><h6>เลือก agent</h6></label>
    <v-select
      v-model="selectagent"
      :options="allagent"
      :searchable="true"
      :clearable="true"
      @open="Get_agent"
    ></v-select>
    <label for="name_service" class="mt-2"><h6>เลือก ip cluster</h6></label>
    <v-select
      v-model="selectnameip"
      :options="allname"
      :searchable="true"
      :clearable="true"
      @open="Get_nameip"
    ></v-select>
    <center>
      <button class="btn btn-primary mt-2" @click="Submit()">
        <span v-if="!loading"> ยืนยัน</span>
        <div class="spinner-border" role="status" v-else>
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </center>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      domain_web: null,
      domain_office: null,
      name_service: null,
      status_domain: false,
      domain_status: true,
      loading: false,
      domain_redirect_status: true,
      domainweb_old: null,
      domainoffice_old: null,
      domain_web_status: false,
      allmaster: [],
      selectmaster: null,
      allagent: [],
      selectagent: null,
      ip_set: [],
      allname:[],
      selectnameip:null
    };
  },
  methods: {
    Submit() {
      const body = {
        usermaster: this.selectmaster.value,
        useragent: this.selectagent.value.username.username,
        name: this.selectnameip.value,
      };
      this.loading= true
      this.axios
        .post(domain + "/api/setip_agent", body)
        .then((result) => {
          this.loading= false
          if (result.data.code === 0) {
            this.$swal({
              title: "success",
              text: result.data.msg,
              icon: "success",
            });
          } else {
            this.$swal({
              title: "error",
              text: result.data.msg,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading= false
          return err;
        });
    },
    isValidDomain(domain) {
      // สร้าง Regular Expression สำหรับตรวจสอบโดเมนที่ถูกต้อง
      const domainRegex = /^(?!:\/\/)([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}$/;

      // ใช้ test() เพื่อตรวจสอบว่าโดเมนที่ป้อนเข้ามาถูกต้องหรือไม่
      return domainRegex.test(domain);
    },
    Get_master() {
      if (this.allmaster.length <= 0) {
        this.axios
          .get(domain + "/api/GetUserMaster")
          .then((result) => {
            // if (result.data.code === 0) {
            this.allmaster = [];
            result.data.forEach((element) => {
              this.allmaster.push({
                value: element.username,
                label: element.username,
              });
            });
            // alert(result.data.message.message);
            // } else {
            //   alert(result.data.msg);
            // }
          })
          .catch((err) => {
            return err;
          });
      }
    },
    Get_agent() {
      if (this.allagent <= 0) {
        this.axios
          .get(domain + "/api/GetAllAgent")
          .then((result) => {
            result.data.forEach((element) => {
              this.allagent.push({
                value: {
                  username: element,
                },
                label: element.username,
              });
            });
          })
          .catch((err) => {
            return err;
          });
      }
    },
    Get_nameip() {
      if (this.allname.length <= 0) {
        this.axios
          .get(domain + "/api/white_listip")
          .then((result) => {
            // if (result.data.code === 0) {
            this.allname = [];
            result.data.payload.forEach((element) => {
              this.allname.push({
                value: element.name,
                label: element.name,
              });
            });
            // alert(result.data.message.message);
            // } else {
            //   alert(result.data.msg);
            // }
          })
          .catch((err) => {
            return err;
          });
      }
    }
  },
  mounted() {
    if (localStorage.getItem("token")) {
      if (process.env.NODE_ENV !== "development") {
        domain = location.origin;
        console.log(domain);
        // this.Get_master()
      } else {
        domain = "http://localhost:6900";
        // this.Get_master()
      }
    } else {
      this.$router.push("/Login_web");
    }
  },
};
</script>

<style></style>
