<template>
  <!-- <Login /> -->
  <router-view />
</template>
<script>
// let domain = "";
// import Login from "./components/Login.vue";
export default {
  components: {
    // Login,
  },
  mounted () {
    // if (localStorage.getItem("token")) {
    //   domain = location.origin;
    //   this.GetAlldata();
    // } else {
    //   console.log(domain);
    //   this.$router.push("/Login_web");
    // }
  },
};
</script>
<style></style>
