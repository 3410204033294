<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div v-if="!domain_web_status">
      <div v-if="!status_delete && !status_setdomain">
        <label for="name_service" class="mt-2"><h6>ชื่อ service</h6></label>
        <input type="text" v-model="name_service" class="form-control" />
        <center>
          <button class="btn btn-primary mt-2" @click="getDomain()">
            <span v-if="!loading"> ดูข้อมูล Domain</span>
            <div class="spinner-border" role="status" v-else>
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
        </center>
      </div>
      <input
        class="form-check-input mx-1"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="status_setdomain"
      />
      <label class="form-check-label" for="flexCheckDefault">
        เพิ่ม-ลบโดเมนสำรองเว็บ
      </label>
      <br />
      <div v-if="status_setdomain">
        <div>
          <label for="domain_web" class="mt-2"
            ><h6>โดเมนเว็บ (ไม่มี https)</h6></label
          ><input type="text" v-model="domain_web" class="form-control" />
        </div>
        <div>
          <label for="domain_office" class="mt-2">
            <h6>โดเมน office (ไม่มี https)</h6></label
          >
          <input type="text" v-model="domain_office" class="form-control" />
        </div>
        <label for="name_service" class="mt-2"><h6>ชื่อ service</h6></label>
        <input type="text" v-model="name_service" class="form-control" />
        <center>
          <button class="btn btn-danger mt-2 mx-2" @click="submit('delete')">
            <span v-if="!loading"> ลบข้อมูล</span>
            <div class="spinner-border" role="status" v-else>
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
          <button class="btn btn-success mt-2" @click="submit('add')">
            <span v-if="!loading"> เพิ่มข้อมูล</span>
            <div class="spinner-border" role="status" v-else>
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
        </center>
      </div>
    </div>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      domain_web: null,
      domain_office: null,
      name_service: null,
      status_domain: false,
      domain_status: true,
      loading: false,
      domain_redirect_status: true,
      domainweb_old: null,
      domainoffice_old: null,
      domain_web_status: false,
      status_delete: false,
      status_setdomain: false,
    };
  },
  methods: {
    submit(type) {
      if (
        this.domain_web &&
        this.domain_office &&
        this.name_service
      ) {
        this.loading = true;
        this.axios
          .get(
            `${domain}${
              type === "add"
                ? "/api/set_multidomain"
                : "/api/delete_multidomain"
            }?service=${this.name_service}&domain_web=${
              this.domain_web.toLowerCase()
            }&domain_office=${this.domain_office.toLowerCase()}`
          )
          .then((result) => {
            this.loading = false;
            if (result.data.code == 200) {
              this.$swal({
                title: "success",
                html: result.data.msg,
                icon: "success",
              });
              this.name_service = null
              this.domain_web = null
              this.domain_office = null
            } else {
              this.$swal({
                title: "error",
                html: result.data.msg,
                icon: "error",
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      } else {
        this.$swal({
          title: "เกิดข้อผิดพลาด",
          text: "โปรดตรวจสอบข้อมูล",
          icon: "error",
        });
      }
    },
    getDomain() {
      if (this.name_service) {
        // /api/getdomain
        this.loading = true;
        this.axios
          .get(`${domain}/api/get_multidomain?service=${this.name_service}`)
          .then((result) => {
            this.loading = false;
            if (result.data.code == 0) {
              // สมมติว่า result.data.payload เป็น array ของข้อมูลที่คุณต้องการแสดง
              if (result.data.payload.length > 0) {
                const payload = result.data.payload;
                // สร้าง HTML string จากข้อมูลใน payload
                const htmlContent = payload
                  .map(
                    (data, index) =>
                      `<div key="${index}"> WEB-${index + 1}: <br> ${
                        data.DomainWeb
                      } <br> OFFICE-${index + 1}: <br> ${
                        data.DomainOffice
                      } <br> -------------------------- <br></div>`
                  )
                  .join("");

                this.$swal({
                  title: "success",
                  html: htmlContent,
                  icon: "success",
                });
              } else {
                this.$swal({
                  title: "error",
                  html: "ไม่พบโดเมนสำรอง",
                  icon: "error",
                });
              }

              this.name_service = null;
            } else {
              this.$swal({
                title: "error",
                text: result.data.msg,
                icon: "error",
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            return err;
          });
      } else {
        this.$swal({
          title: "เกิดข้อผิดพลาด",
          text: "กรุณากรอกชื่อ service",
          icon: "error",
        });
      }
    },
    // isValidDomain(domain) {
    //   // สร้าง Regular Expression สำหรับตรวจสอบโดเมนที่ถูกต้อง
    //   const domainRegex = /^(?!:\/\/)([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}$/;

    //   // ใช้ test() เพื่อตรวจสอบว่าโดเมนที่ป้อนเข้ามาถูกต้องหรือไม่
    //   return domainRegex.test(domain);
    // },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      if (process.env.NODE_ENV !== "development") {
        domain = location.origin;
        console.log(domain);
      } else {
        domain = "http://localhost:6900";
      }
    } else {
      this.$router.push("/Login_web");
    }
  },
};
</script>

<style></style>
