<template>
  <div id="seettingOffice" class="">
    <center>
      <button
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        สร้างงาน
      </button>
    </center>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">กรอกรายละเอียด</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="textoffice" class="form-label">senior</label>
              <v-select
                v-model="selectsenior"
                :options="allsenior"
                :searchable="true"
                :clearable="true"
              ></v-select>
            </div>
            <div class="mb-3">
              <label for="textoffice" class="form-label">master</label>
              <v-select
                v-model="selectmaster"
                :options="allmaster"
                :searchable="true"
                :clearable="true"
              ></v-select>
            </div>
            <div class="mb-3">
              <label for="textoffice" class="form-label">agent</label>
              <v-select
                v-model="showdata"
                :options="allagent"
                :searchable="true"
                :clearable="true"
                class="my-2"
              ></v-select>
            </div>
            <div class="mb-3">
              <label for="textoffice" class="form-label"
                >ลิ้ง office (ไม่ต้องมี https://)</label
              >
              <input
                type="text"
                class="form-control"
                id="textoffice"
                aria-describedby="emailHelp"
                v-model="office_url"
              />
            </div>
            <div class="mb-3">
              <label for="textweb" class="form-label"
                >ลิ้งหน้าระบบ (ไม่ต้องมี https://)</label
              >
              <input
                type="text"
                class="form-control"
                id="textweb"
                v-model="web_url"
              />
            </div>
            <div>
              เลือก setting
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  v-model="defaultgame"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  ดึงเกม
                </label>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  v-model="setsms"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  เซ็ต sms
                </label>
                <div v-if="setsms">
                  <v-select
                    v-model="sms_otp_set"
                    :options="data_sms_otp"
                    :searchable="true"
                    :clearable="true"
                  ></v-select>
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  v-model="settheme"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  เซ็ต Theme
                </label>
                <div v-if="settheme">
                  <v-select
                    v-model="data_theme_set"
                    :options="data_theme"
                    :searchable="true"
                    :clearable="true"
                  ></v-select>
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  v-model="setwheel"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  set กงล้อ
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  v-model="whitelabel"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  set whitelabel
                </label>
                <div v-if="whitelabel">
                  <v-select
                    v-model="whitelabel_set"
                    :options="data_whitelabel"
                    :searchable="true"
                    :clearable="true"
                  ></v-select>
                </div>
              </div>
            </div>
            <center>
              <button @click="Push_data()" class="btn btn-primary">
                Submit
              </button>
            </center>
          </div>
        </div>
      </div>
    </div>
    <div v-if="list_job">
      <h3>List งาน</h3>
      <div v-for="item in list_job" :key="item.id">
        <div class="card my-2">
          <div class="card-body">
            <div class="row">
              <div class="col-4 align-self-center">
                WEB : {{ item.web_url }} <br />
                Theme : {{ item.set_theme }} <br />
                whitelabel :
                {{ item.whitelabel_set ? item.whitelabel_set : "ไม่มี" }}
              </div>
              <div class="col-8 align-self-center">
                <button
                  v-if="!item.token"
                  class="btn btn-success btn-sm mx-2"
                  @click="loginOffice(item)"
                >
                  <span v-if="!loading"> ล็อคอิน OFFICE</span>
                  <div class="spinner-border" role="status" v-else>
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
                <button
                  v-if="!item.setOffice"
                  class="btn btn-primary btn-sm mx-2"
                  @click="settingoffice(item)"
                >
                  <span v-if="!loading"> Set OFFICE</span>
                  <div class="spinner-border" role="status" v-else>
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
                <button
                  v-if="!item.setRegister"
                  class="btn btn-warning btn-sm mx-2"
                  @click="registerweb(item)"
                >
                  <span v-if="!loading"> สมัครหน้าเว็บ</span>
                  <div class="spinner-border" role="status" v-else>
                    <span class="visually-hidden">Loading...</span>
                  </div></button
                ><button
                  v-if="!item.setAddcredit"
                  class="btn btn-info btn-sm mx-2"
                  @click="addcredit(item)"
                >
                  <span v-if="!loading"> แอดเครดิต</span>
                  <div class="spinner-border" role="status" v-else>
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
                <button
                  class="btn btn-danger btn-sm mx-2"
                  @click="deletejob(item)"
                >
                  <span v-if="!loading"> เสร็จสิ้น</span>
                  <div class="spinner-border" role="status" v-else>
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      data_list: null,
      defaultgame: true,
      whitelabel: false,
      setsms: true,
      setwheel: true,
      whitelabel_set: "",
      sms_otp_set: "",
      data_whitelabel: [],
      data_sms_otp: [],
      data_theme: [],
      office_url: null,
      web_url: null,
      list_job: null,
      settheme: true,
      data_theme_set: null,
      loading: false,
      allsenior: [],
      allmaster: [],
      allagent:[],
      selectsenior: null,
      selectmaster: null,
      showdata: null,
    };
  },
  methods: {
    Get_data() {
      this.axios
        .get(domain + "/api/data_office")
        .then((result) => {
          if (result.data.code === 0) {
            this.data_list = result.data.payload;
            this.data_list.list_whitelabel.forEach((element) => {
              this.data_whitelabel.push({
                value: element,
                label: element,
              });
            });
            this.data_list.list_user_sms.forEach((element) => {
              let datatext = null;
              if (element === "upotp01") datatext = element + " (NEWTOPUP)";
              if (element === "otp01") datatext = element + " (G2E)";
              if (element === "mtotp01") datatext = element + " (MOTUI)";
              if (element === "esotp01") datatext = element + " (ESCOBAR)";
              if (element === "techotp01") datatext = element + " (ABATECH)";
              this.data_sms_otp.push({
                value: element,
                label: datatext,
              });
            });
            this.data_list.list_theme.forEach((element) => {
              this.data_theme.push({
                value: element,
                label: element,
              });
            });
            // alert(result.data.message.message);
          } else {
            alert(result.data.msg);
          }
        })
        .catch((err) => {
          return err;
        });
    },
    Get_listJob() {
      this.axios
        .get(domain + "/api/list_job")
        .then((result) => {
          if (result.data.code === 0) {
            this.list_job = result.data.payload;

            // alert(result.data.message.message);
          } else {
            alert(result.data.msg);
          }
        })
        .catch((err) => {
          return err;
        });
    },
    Push_data() {
      if (this.isValidURL(this.office_url)) {
        if (this.isValidURL(this.web_url)) {
          let data_obj = {
            office_url: this.office_url,
            web_url: this.web_url,
            whitelabel: this.whitelabel,
            setwheel: this.setwheel,
            setsms: this.setsms,
            whitelabel_set:
              this.whitelabel_set === "" ? "" : this.whitelabel_set.value,
            sms_otp_set:
              this.sms_otp_set === "" ? "otp01" : this.sms_otp_set.value,
            defaultoffice: false,
            data_theme_set: this.data_theme_set.value,
            senior:this.selectsenior.value,
            master:this.selectmaster.value,
            agent:this.showdata.value.username.username
          };
          this.axios
            .post(domain + "/api/create_job", data_obj)
            .then((result) => {
              this.loading = false;
              if (result.data.code === 0) {
                this.$swal({
                  title: "สำเร็จ",
                  text: result.data.msg,
                  icon: "success",
                });
                this.Get_listJob();
              } else {
                this.$swal({
                  title: "เกิดข้อผิดพลาด",
                  text: result.data.payload.message.message,
                  icon: "error",
                });
              }
            })
            .catch((err) => {
              this.loading = false;
              return err;
            });
        } else {
          this.$swal({
            title: "กรุณาตรวจสอบลิ้งหน้าระบบ",
            icon: "error",
          });
          this.web_url = null;
        }
      } else {
        this.$swal({
          title: "กรุณาตรวจสอบลิ้งหลังบ้าน",
          icon: "error",
        });
        this.office_url = null;
      }
    },
    isValidURL(str) {
      const pattern = new RegExp(
        "^([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,}" + // domain name
          "(\\:\\d+)?(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?$",
        "i"
      );
      return !!pattern.test(str);
    },
    settingoffice(data) {
      this.loading = true;
      this.axios
        .post(domain + "/api/officesetup", data)
        .then((result) => {
          this.loading = false;
          console.log(result.data.code);
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.msg,
              icon: "success",
            });
            this.Get_listJob();
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.payload.message.message,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    registerweb(data) {
      this.loading = true;
      this.axios
        .post(domain + "/api/registerweb", data)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.msg,
              icon: "success",
            });
            this.Get_listJob();
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.message,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    addcredit(data) {
      this.loading = true;
      this.axios
        .post(domain + "/api/addcredit", data)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.msg,
              icon: "success",
            });
            this.Get_listJob();
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.payload.message.message,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    deletejob(data) {
      this.loading = true;
      this.axios
        .post(domain + "/api/deletejob", data)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.msg,
              icon: "success",
            });
            this.Get_listJob();
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.payload.message.message,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    loginOffice(data) {
      this.loading = true;
      console.log(data);
      this.axios
        .post(domain + "/api/loginOffice", data)
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "สำเร็จ",
              text: result.data.msg,
              icon: "success",
            });
            this.Get_listJob();
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.payload.message.message,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    Get_master() {
      this.axios
        .get(domain + "/api/GetMaster")
        .then((result) => {
          if (result.data.code === 0) {
            result.data.payload.forEach((element) => {
              this.allsenior.push({
                value: element.username,
                label: element.username,
              });
            });
            // alert(result.data.message.message);
          } else {
            alert(result.data.msg);
          }
        })
        .catch((err) => {
          return err;
        });
    },
    getmaster() {
      this.axios
        .get(domain + "/api/GetUserMaster")
        .then((result) => {
          // this.allmaster = result.data;
          result.data.forEach((element) => {
            this.allmaster.push({
              value: element.username,
              label: element.username,
            });
          });
        })
        .catch((err) => {
          return err;
        });
    },
    GetAlldata() {
      this.axios
        .get(domain + "/api/GetAllAgent")
        .then((result) => {
          result.data.forEach((element) => {
            this.allagent.push({
              value: {
                username: element,
              },
              label: element.username,
            });
          });
        })
        .catch((err) => {
          return err;
        });
    },
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
      this.Get_data();
      this.Get_listJob();
      this.Get_master();
      this.getmaster();
      this.GetAlldata()
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
      this.Get_data();
      this.Get_listJob();
      this.Get_master();
      this.getmaster();
      this.GetAlldata()
    }
  },
};
</script>

<style></style>
